import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "../css/service.css";
import helpers from "../Helper";
import Datanotfound from "./Datanotfound";
import Meta from "./Meta";
import CmsLoader from "../loader/Cms1";
import Page404 from "./layouts/Page404";
import { Helmet } from "react-helmet-async";
export default function Services() {
  const params = useParams();
  const [dataNotFound, setDataNotFound] = useState(false);
  const [categorieslist, setCategorieslist] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loader, setLoader] = useState(false);
  const getCategorielists = async () => {
    await axios
      .get(process.env.REACT_APP_API + "api/services/" + params.category)
      .then((response) => {
        setCategorieslist(response.data.data.data);
        setLoader(false);
      });
  };
  const getCategorie = async () => {
    await axios
      .get(
        process.env.REACT_APP_API + "api/service-category/" + params.category
      )
      .then((response) => {
        setDataNotFound(false);
        setCategories(response.data.data);
      })
      .catch((err) => {
        setDataNotFound(!dataNotFound);
      });
  };
  useEffect(() => {
    setLoader(true);
    getCategorielists();
    getCategorie();
    setDataNotFound(false);
    // eslint-disable-next-line
  }, [params]);

  return (
    <>
      {dataNotFound && !loader ? (
        <Page404 />
      ) : (
        <>
          <Helmet>
            <title>{categories.meta_title}</title>
            <meta
              name="description"
              content={categories.meta_description}
            />
            <meta
              name="keywords"
              content={categories.meta_keywords}
            />
          </Helmet>
          <main>
            <section className="InnerHead bg-black">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 150">
                <path
                  fill="var(--thm)"
                  d="M1600,82.42s-107.75-175-500-15c-436.7,178.11-853.54,10-962,10S17.91,99.69,0,109.42c.06,0,0,37,0,37H1600Z"
                />
                <path
                  fill="var(--white)"
                  d="M1600,118.42s-94.07-190.17-470-54c-458.06,165.92-926,27-1042,27-42.61,0-70.09,7.27-88,17,.06,0,0,42,0,42H1600Z"
                />
              </svg>
              <picture>
                <source
                  srcSet={require("../assets/img/banner1.webp")}
                  type="image/webp"
                />
                <img
                  src={require("../assets/img/banner1.jpg")}
                  className="imgbg"
                  alt="FAQs"
                  width="1400"
                  height="520"
                />
              </picture>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-9 text-center">
                    <h1 className="fw-normal text-white">{categories.title}</h1>
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <img
                            src={require("../assets/img/home.svg").default}
                            width="14"
                            height="14"
                            alt="Home"
                          />
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/services">Services</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <span>{categories.title}</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
            {loader ? (
              <CmsLoader />
            ) : (
              <section className="Services">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-9 col-md-10 text-center">
                      {categories.title ? (
                        <span className="SubTitle">{categories.title}</span>
                      ) : (
                        ""
                      )}
                      <h2 className="Heading">
                        {categories.title
                          ? helpers.sepratestring(categories.title)[0]
                          : ""}{" "}
                        <span>
                          {categories.title
                            ? helpers.sepratestring(categories.title)[1]
                            : ""}
                        </span>
                      </h2>
                      <div
                        className="col-12"
                        dangerouslySetInnerHTML={{
                          __html: categories.description,
                        }}
                      />
                    </div>
                  </div>
                  {categorieslist?.length > 0 ? (
                    <div className="row text-center list">
                      {categorieslist.map((cate, ct) => (
                        <div key={ct} className="col-xl-3 col-md-4 col-6 mb-4">
                          <Link
                            to={"/service/" + cate.alias}
                            className="card SerBlock"
                          >
                            <div className="card-header">
                              {helpers.helpers1(cate.image) ? (
                                <picture>
                                  <img
                                    loading="lazy"
                                    src={
                                      process.env.REACT_APP_API +
                                      "uploads/service/" +
                                      cate.image
                                    }
                                    alt={cate.title}
                                    width="121"
                                    height="131"
                                  />
                                </picture>
                              ) : (
                                <picture>
                                  <source
                                    srcSet={
                                      process.env.REACT_APP_API +
                                      "uploads/service/webp/" +
                                      cate.image +
                                      ".webp"
                                    }
                                    type="image/webp"
                                  />
                                  <source
                                    srcSet={
                                      process.env.REACT_APP_API +
                                      "uploads/service/avif/" +
                                      cate.image +
                                      ".avif"
                                    }
                                    type="image/avif"
                                  />
                                  <img
                                    loading="lazy"
                                    src={
                                      process.env.REACT_APP_API +
                                      "uploads/service/jpg/" +
                                      cate.image +
                                      ".jpg"
                                    }
                                    alt={cate.title}
                                    width="121"
                                    height="131"
                                  />
                                </picture>
                              )}
                            </div>
                            <div className="card-body px-2">
                              <h3 className="h5">{cate.title}</h3>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <Datanotfound />
                  )}
                </div>
              </section>
            )}
          </main>
        </>
      )}
    </>
  );
}
