import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
  <div className="container">
  <ContentLoader viewBox="0 0 600 100" backgroundColor="#f3f3f3" foregroundColor="#c7f9ff">
    <rect x="95" y="40" rx="2" ry="2" width="390" height="21" /> 
    <rect x="127" y="80" rx="2" ry="2" width="145" height="11" /> 
    <rect x="307" y="80" rx="2" ry="2" width="145" height="11" /> 
  </ContentLoader>
    <ContentLoader viewBox="0 0 400 130" backgroundColor="#f3f3f3" foregroundColor="#c7f9ff">
      <rect x="50" y="10" rx="4" ry="4" width="300" height="30" />
      <rect x="50" y="50" rx="4" ry="4" width="300" height="30" />
      <rect x="50" y="90" rx="4" ry="4" width="300" height="30" />
    </ContentLoader>
  </div>
)

export default MyLoader