import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
  <div className="container">
    <ContentLoader viewBox="0 0 600 100" backgroundColor="#f3f3f3" foregroundColor="#c7f9ff">
      <rect x="95" y="40" rx="2" ry="2" width="390" height="21" /> 
      <rect x="127" y="80" rx="2" ry="2" width="145" height="11" /> 
      <rect x="307" y="80" rx="2" ry="2" width="145" height="11" /> 
    </ContentLoader>
    <ContentLoader viewBox="0 0 400 200" backgroundColor="#f3f3f3" foregroundColor="#c7f9ff">
      <rect x="30" y="20" rx="4" ry="4" width="80" height="9" />
      <rect x="30" y="40" rx="4" ry="4" width="80" height="9" />
      <rect x="30" y="60" rx="4" ry="4" width="80" height="9" />
      <rect x="30" y="80" rx="4" ry="4" width="80" height="9" />
      <rect x="30" y="100" rx="4" ry="4" width="80" height="9" />
      <rect x="30" y="120" rx="4" ry="4" width="80" height="9" />
      <rect x="150" y="20" rx="0" ry="0" width="70" height="70" />
      <rect x="240" y="20" rx="0" ry="0" width="70" height="70" />
      <rect x="320" y="20" rx="0" ry="0" width="70" height="70" />
      <rect x="150" y="100" rx="2" ry="2" width="70" height="70" />
      <rect x="240" y="100" rx="2" ry="2" width="70" height="70" />
      <rect x="320" y="100" rx="2" ry="2" width="70" height="70" />
    </ContentLoader>
  </div>
)

export default MyLoader