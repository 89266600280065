import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
export default function Page404() {
  return (
    <>
        <Helmet>
            <title>PAGE NOT FOUND : Electrical & Communications</title>
            <meta name="description" content="Welcome to Electrical & Communications" />
            <meta name="keywords" content="Welcome to Electrical & Communications" />
        </Helmet>
        <main>
            <section className="Sec1">
                <div className="container">
                    <div className="text-center">
                        <img src="https://static.vecteezy.com/system/resources/thumbnails/006/549/647/small/404-landing-page-free-vector.jpg" alt="" srcSet="" />
                        <h5 className='mt-5 text-secondary'>PAGE NOT FOUND</h5>
                        <p className='mb-0 text-secondary'>This page you are looking for doesn`t exists or an other error occurred.</p>
                        <p className='text-secondary'><Link to="/">Go Back To Home</Link>, or head over to website to choose a new direction.</p>
                    </div>
                </div>
            </section>
        </main>
    </>
  )
}
