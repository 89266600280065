import React, { useEffect, useState } from 'react'
import { NavLink, Link } from 'react-router-dom';
import axios from 'axios';
export default function Header() {
    const [categories, setCategories] = useState([]);
    const [socialmedias, setSocialMedias] = useState([]);
    const [settingdata, setSettingdata] = useState();
    useEffect(() => {
        async function getCategorie() {
            await axios.get(process.env.REACT_APP_API + 'api/service-category/').then((response) => {
                setCategories(response.data.data.data);
            });
        }
        getCategorie();
    },[setCategories]);

    useEffect(() => {
        async function getsocialmedia() {
            await axios.get(process.env.REACT_APP_API + 'api/socialmedias/').then((response) => {
                setSocialMedias(response.data.data);
            });
        }
        getsocialmedia();
    }, [setSocialMedias]);

    useEffect(() => {
        async function getsettingdata() {
            await axios.get(process.env.REACT_APP_API + 'api/settingdata/').then((response) => {
                let ContactArr = response.data.data.header_contact_number.split(',');
                let EmailArr = response.data.data.header_email_address.split(',');
                setSettingdata({contactnumber:ContactArr,emailaddress:EmailArr});
            });
        }
        getsettingdata();
    }, [setSettingdata]);
    
    const [NavbarOpen, setNavbarOpen] = useState(false);

    return (
        <>
            <header className="d-none bgthm d-lg-block">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            {
                                socialmedias.length > 0 ?
                                    <ul className="icons p-0 m-0">
                                        {
                                            socialmedias.map((socialmedia, sm) =>
                                            <li key={sm}>
                                                {
                                                    socialmedia.title==='facebook' ? 
                                                    <a href={socialmedia.link} target="_blank" rel="noreferrer" title="Facebook"><img loading="lazy" src={require('../../assets/img/facebook-w.svg').default} alt={socialmedia.title} width="24" height="24" /></a>
                                                    :''
                                                }
                                                {
                                                    socialmedia.title==='twitter' ? 
                                                    <a href={socialmedia.link} target="_blank" rel="noreferrer" title="Twitter"><img loading="lazy" src={require('../../assets/img/twitter-w.svg').default} alt={socialmedia.title} width="24" height="24" /></a>
                                                    :''
                                                }
                                                {
                                                    socialmedia.title==='linkedin' ? 
                                                    <a href={socialmedia.link} target="_blank" rel="noreferrer" title="Linkedin"><img loading="lazy" src={require('../../assets/img/linkedin-w.svg').default} alt={socialmedia.title} width="24" height="24" /></a>
                                                    :''
                                                }
                                                {
                                                    socialmedia.title==='instagram' ? 
                                                    <a href={socialmedia.link} target="_blank" rel="noreferrer" title="Instagram"><img loading="lazy" src={require('../../assets/img/instagram-w.svg').default} alt={socialmedia.title} width="24" height="24" /></a>
                                                    :''
                                                }
                                                {
                                                    socialmedia.title==='youtube' ? 
                                                    <a href={socialmedia.link} target="_blank" rel="noreferrer" title="Youtube"><img loading="lazy" src={require('../../assets/img/youtube-w.svg').default} alt={socialmedia.title} width="24" height="24" /></a>
                                                    :''
                                                }
                                            </li>                                                
                                            )
                                        }
                                        <li className="d-none d-lg-inline-block"><a href="https://api.whatsapp.com/send?phone=+919898989898&amp;text=Hi, I would like to get more information.." rel="noreferrer" target="_blank" title="Whatsapp"><img loading="lazy" src={require('../../assets/img/whatsapp-w.svg').default} alt="Whatsapp" width="24" height="24" /></a></li>
                                        <li className="d-lg-none"><a href="whatsapp://send?text=Hi, I would like to get more information..!&amp;phone=+919898989898" target="_blank" rel="noreferrer" title="Whatsapp"><img loading="lazy" src={require('../../assets/img/whatsapp-w.svg').default} alt="Whatsapp" width="24" height="24" /></a></li>
                                    </ul>
                                    : ''
                            }

                        </div>
                        <div className="col-md-6 text-end">
                            <ul className="d-flex small justify-content-end m-0 p-0">
                                {
                                    settingdata ?
                                        <>
                                            <li className="me-4">
                                            <img loading="lazy" src={require('../../assets/img/phone-w.svg').default} alt="Phone No." width="20" height="20" className="me-2" /> 
                                                {
                                                    settingdata.contactnumber.map((number,nu)=>
                                                        <a href={"tel:" + number} key={nu}> 
                                                            {number} 
                                                            { nu !== (settingdata.contactnumber.length - 1) ? ',' : ''}
                                                        </a>
                                                    )
                                                }
                                                
                                            </li>

                                            <li>
                                                <img loading="lazy" src={require('../../assets/img/mail-w.svg').default} alt="Email ID" width="20" height="20" className="me-2" /> 
                                                {
                                                    settingdata.emailaddress.map((email,em)=>
                                                        <a href={"mailto:" + email} key={em}> 
                                                            {email}
                                                            { em < (settingdata.emailaddress.length - 1) ? ',' : ''}
                                                        </a>                                                         
                                                    )
                                                }                                                
                                            </li>                                            
                                        </>
                                        : ''
                                }


                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            <nav className="navbar menu">
                {/* <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 209"><path fill="var(--thm)" d="M1600,41.81s-217-137-550,85c-265.41,176.94-797.95,10-912,10-42.61-.32-120.09,22.27-138,32,.06,0,0,37,0,37H1600Z"/><path fill="#fff" d="M1600,57.81s-267-99-540,66-856,27-972,27c-42.61,0-70.09,7.27-88,17,.06,0,0,42,0,42H1600Z"/></svg> --> */}
                <div className="st">
                    <div className="container">
                        <div className="row">
                            <div className="col logom">
                                <button aria-label="Navbar Button" className={NavbarOpen ? ' navbar-toggler MenuIcon d-lg-none' : 'navbar-toggler MenuIcon offcanvasClose d-lg-none'} onClick={() => setNavbarOpen((NavbarOpen) => !NavbarOpen)}><svg viewBox="0 0 30 26" fill="var(--black)"><path d="M9.42,19.56l-6.5-6.51L9.56,6.41C10.62,5.35,9.26,3.83,8.07,5l-8,8,8,8C9.08,22,10.52,20.66,9.42,19.56Z" /><path className="bar1" d="M1,0H29a1,1,0,0,1,0,2H1A1,1,0,0,1,1,0Z" /><path className="bar2" d="M1,24H29a1,1,0,0,1,0,2H1a1,1,0,1,1,0-2Z" /><path d="M10.93,12H29a1,1,0,0,1,0,2H10.93a1,1,0,0,1,0-2Z" /></svg></button>
                                <Link to="/" className="logo" title="Intelligence Security of India">
                                    <img src={require('../../assets/img/logo.svg').default} width="150" height="150" className="w-100" alt="Intelligence Security of India" />
                                </Link>
                            </div>
                            <div className="col MenuBar">
                                <div className={NavbarOpen ? ' offcanvas offcanvas-start justify-content-between show' : 'offcanvas offcanvas-start justify-content-between'} id="navigatin" data-bs-scroll="false" data-bs-backdrop="false" tabIndex="-1">
                                    <ul className="navbar-nav">
                                        <li className="nav-item"><NavLink className="nav-link" title="Home" to="/" onClick={() => setNavbarOpen(false)}><span>Home</span></NavLink></li>
                                        <li className="nav-item"><NavLink className="nav-link" title="About Us" to="about" onClick={() => setNavbarOpen(false)}><span>About Us</span></NavLink></li>
                                        <li className="nav-item dropdown">
                                            <NavLink title="Services" className="nav-link dropdown-toggle d-none d-lg-block" to="/services" role="button" aria-haspopup="true" aria-expanded="false" onClick={() => setNavbarOpen(false)}><span>Services</span></NavLink>
                                            <a title="Services" href='/#' className="nav-link dropdown-toggle d-lg-none" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span>Services</span></a>
                                            <ul className="dropdown-menu" aria-labelledby="servicesDropdown">
                                                {
                                                    categories.map((category, c) =>
                                                        <li key={c}>
                                                            <NavLink title={category.title} to={"/services/" + category.alias} onClick={() => setNavbarOpen(false)}>
                                                                {category.title}
                                                            </NavLink>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </li>
                                        <li className="nav-item"><NavLink className="nav-link" title="Gallery" to="gallery" onClick={() => setNavbarOpen(false)}><span>Gallery</span></NavLink></li>
                                        <li className="nav-item"><NavLink className="nav-link" title="Testimonial" to="testimonials" onClick={() => setNavbarOpen(false)}><span>Testimonial</span></NavLink></li>
                                        <li className="nav-item"><NavLink className="nav-link" title="Blogs" to="blogs" onClick={() => setNavbarOpen(false)}><span>Blogs</span></NavLink></li>
                                        <li className="nav-item"><NavLink className="nav-link" title="Contact Us" to="contact" onClick={() => setNavbarOpen(false)}><span>Contact Us</span></NavLink></li>
                                    </ul>
                                </div>
                                <div className="LastH">
                                    <a href="tel:+19898989898" className="IconImg d-lg-none" title="Call Now"><img src={require('../../assets/img/support.svg').default} width="42" height="42" alt="support" /></a>
                                    <a href="mailto:contact@yourdomain.com" className="IconImg d-lg-none" title="Email"><img src={require('../../assets/img/mail.svg').default} width="42" height="42" alt="email" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}
