
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/assets/css/main-style.css';
import '../src/assets/css/style.css';
import About from './components/About';
import Blog from './components/Blog';
import BlogCategory from './components/BlogCategory';
import Blogdetail from './components/Blogdetail';
import Career from './components/Career';
import Contact from './components/Contact';
import Faq from './components/Faq';
import Gallery from './components/Gallery';
import Home from './components/Home';
import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';
import Page404 from './components/layouts/Page404';
import Privacy from './components/Privacy';
import Services from './components/Services';
import ServicesCategory from './components/ServicesCategory';
import ServicesDetail from './components/ServicesDetail';
import Terms from './components/Terms';
import Testimonial from './components/Testimonial';
import Scrolltop from './components/layouts/Scrolltop';

function App() {
  return (
    <>
      <BrowserRouter basename={'/'}>
        <Scrolltop />
        <HelmetProvider>
          <Header />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/testimonials' element={<Testimonial />} />
            <Route path='/gallery' element={<Gallery />} />
            <Route path='/blogs' element={<Blog />} />
            <Route path="/blog/:alias" element={<Blogdetail />} />
            <Route path='/blog-catgeory/:alias' element={<BlogCategory />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/careers' element={<Career />} />
            <Route path='/faq' element={<Faq />} />
            <Route path='/privacy-policy' element={<Privacy />} />
            <Route path='/terms-and-conditions' element={<Terms />} />
            <Route path='/services' element={<ServicesCategory />} />
            <Route path='/services/:category' element={<Services />} />
            <Route path='/service/:alias' element={<ServicesDetail />} />
            <Route path='/*' element={<Page404 />} />
          </Routes>
          <Footer />
        </HelmetProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
