import React from 'react'
import { RotatingLines } from 'react-loader-spinner'

export default function PageLoader() {
  return (
    <>
      <div className='text-center mt-5 mb-5'>
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="30"
          visible={true}
        /> Loading Please Wait...
      </div>
    </>
  )
}
