import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import '../css/gallery.css';
import helpers from '../Helper';
import Meta from './Meta';
import GalleryLoader from '../loader/Gallery';
export default function Gallery() {
    const [gallerydata, setgallerydata] = useState([]);
    const [cmsdata, setcmsdata] = useState([]);
    const [loader, setLoader] = useState(false);
    async function getgallerydata() {
        await axios.get(process.env.REACT_APP_API + 'api/galleries').then((responose) => {
            setgallerydata(responose.data.data.data);
            setLoader(false);
        });
    }
    async function getcmsdata() {
        await axios.get(process.env.REACT_APP_API + 'api/getcms/13').then((response) => {
            setcmsdata(response.data.data);
        });
    }
    useEffect(() => {
        setLoader(true);
        getgallerydata();
        getcmsdata();
    }, []);

    return (
        <>
            <Meta id={4} />
            <main>
                <section className="InnerHead bg-black">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 150"><path fill="var(--thm)" d="M1600,82.42s-107.75-175-500-15c-436.7,178.11-853.54,10-962,10S17.91,99.69,0,109.42c.06,0,0,37,0,37H1600Z" /><path fill="var(--white)" d="M1600,118.42s-94.07-190.17-470-54c-458.06,165.92-926,27-1042,27-42.61,0-70.09,7.27-88,17,.06,0,0,42,0,42H1600Z" /></svg>
                    <picture>
                        <source srcSet={require("../assets/img/banner1.webp")} type="image/webp" />
                        <img src={require("../assets/img/banner1.jpg")} className="imgbg" alt="Gallery" width="1400" height="520" />
                    </picture>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9 text-center">
                                <h1 className="fw-normal text-white">Gallery</h1>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/"><img src={require("../assets/img/home.svg").default} width="14" height="14" alt="Home" /></Link></li>
                                    <li className="breadcrumb-item"><span>Gallery</span></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    loader ?
                        <GalleryLoader />
                        :
                        <section>
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-10 col-md-11 text-center">
                                        {
                                            cmsdata.title ? <span className="SubTitle">{cmsdata.title}</span> : ''
                                        }

                                        <h2 className="Heading">
                                            {cmsdata.heading ? helpers.sepratestring(cmsdata.heading)[0] : ''} <span>{cmsdata.heading ? helpers.sepratestring(cmsdata.heading)[1] : ''}</span>
                                        </h2>
                                        <div dangerouslySetInnerHTML={cmsdata.description} />
                                    </div>
                                </div>
                                <div className="row mt-4 justify-content-center">
                                    {
                                        gallerydata.map((gallery, i) =>
                                            <div className="col-lg-3 col-md-4 col-6 mb-4" key={i}>

                                                {
                                                    helpers.helpers1(gallery.image) ?
                                                        <a href={process.env.REACT_APP_API + 'uploads/gallery/' + gallery.image} data-fancybox="gallery" className="gallery card">
                                                            <div className="card-body p-0">
                                                                <picture>
                                                                    <img loading="lazy" src={process.env.REACT_APP_API + 'uploads/gallery/' + gallery.image} alt="Team Building" height="162" width="162" />
                                                                </picture>
                                                            </div>
                                                        </a>
                                                        :
                                                        <a href={process.env.REACT_APP_API + 'uploads/gallery/jpg/' + gallery.image + '.jpg'} data-fancybox="gallery" className="gallery card">
                                                            <div className="card-body p-0">
                                                                <picture>
                                                                    <source srcset={process.env.REACT_APP_API + 'uploads/gallery/webp/' + gallery.image + '.webp'} type="image/webp" />
                                                                    <source srcset={process.env.REACT_APP_API + 'uploads/gallery/avif/' + gallery.image + '.avif'} type="image/avif" />
                                                                    <img loading="lazy" src={process.env.REACT_APP_API + 'uploads/gallery/jpg/' + gallery.image + '.jpg'} alt="Team Building" height="162" width="162" />
                                                                </picture>
                                                            </div>
                                                        </a>
                                                }

                                            </div>
                                        )
                                    }

                                </div>

                            </div>
                        </section>

                }
            </main>
        </>
    )
}
