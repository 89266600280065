import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import $ from 'jquery';
import helpers from '../Helper';
export default function Speciality(props) {
    
    const [missiondata, setmissiondata] = useState([]);
    const [specialitydata, setspecialitydata] = useState([]);
    const [loader, setLoader] = useState(false);
    const [btnloader, setBtnloader] = useState(false);
    const [callbackerror, setCallBackError] = useState({ name: "", email: "", phone: "" });
    const [callbackname, setCallBackName] = useState("");
    const [callbackemail, setCallBackEmail] = useState("");
    const [callbackphone, setCallBackPhone] = useState("");

    const [quotename, setQuoteName] = useState("");
    const [quoteemail, setQuoteEmail] = useState("");
    const [quotephone, setQuotePhone] = useState("");
    const [quoteresponse, setQuoteResponse] = useState("");
    const [quoteproblem, setQuoteproblem] = useState("");
    const [quoteerror, setQuoteError] = useState({ name: "", email: "", phone: "", response: "", problem: "" });

    async function getmissiondata() {
        await axios.get(process.env.REACT_APP_API + 'api/getcms/'+(props?.content ?? 5)).then((responose) => {
            setmissiondata(responose.data.data);
            setLoader(false);
        });
    }

    async function getspecialitydata() {
        await axios.get(process.env.REACT_APP_API + 'api/specialty').then((responose) => {
            setspecialitydata(responose.data.data.data);
        });
    }

    useEffect(() => {
        setLoader(true);
        getmissiondata();
        getspecialitydata();
    }, []);
    

    async function CallBackSubmit(e) {
        setBtnloader(true);
        e.preventDefault();
        let callback = { name: callbackname, email: callbackemail, phone: callbackphone }
        await axios.post(process.env.REACT_APP_API + 'api/callbackrequest/', callback)
            .then((responose) => {
                setCallBackError({ name: '', email: '', phone: '' });
                setCallBackName("");
                setCallBackEmail("");
                setCallBackPhone("");
                setBtnloader(false);
                toast.success(responose.data.message);
                $('[data-bs-dismiss]').click();
            })
            .catch((errors) => {
                let error = errors?.response?.data?.errors;
                setCallBackError({
                    name: error?.name ?? '',
                    email: error?.email ?? '',
                    phone: error?.phone ?? '',
                });
                setBtnloader(false);
            })
    }
    async function QuoteSubmit(e) {
        setBtnloader(true);
        e.preventDefault();
        let callback = { name: quotename, email: quoteemail, phone: quotephone, problem: quoteproblem, response: quoteresponse }
        await axios.post(process.env.REACT_APP_API + 'api/quoterequest/', callback)
            .then((responose) => {
                toast.success(responose.data.message);
                setBtnloader(false);
                $('[data-bs-dismiss]').click();
                setQuoteError({ name: "", email: "", phone: "", response: "", problem: "" });
                setQuoteName("");
                setQuoteEmail("");
                setQuotePhone("");
                setQuoteResponse("");
                setQuoteproblem("");
            })
            .catch((errors) => {
                let error = errors?.response?.data?.errors;
                setQuoteError({
                    name: error?.name ?? '',
                    email: error?.email ?? '',
                    phone: error?.phone ?? '',
                    response: error?.response ?? '',
                    problem: error?.problem ?? ''
                });
                setBtnloader(false);
            })
    }
    return (
        <>
            {
                specialitydata.length > 0 ?
                    <section className="Sec2">
                        <div className="container">
                            <div className="row">
                                {
                                    !loader ?
                                        <>
                                            <div className="col-lg-6">
                                                {missiondata.title ? <span className="SubTitle">{missiondata.title ?? ''}</span> : ''}
                                                <h2 className="Heading">
                                                    {missiondata.heading ? helpers.sepratestring(missiondata.heading)[0] : ''} <span>{missiondata.heading ? helpers.sepratestring(missiondata.heading)[1] : ''}</span>
                                                </h2>
                                                <div dangerouslySetInnerHTML={{ __html: missiondata.description }} />
                                                <div className="mt-4 d-none d-lg-block">
                                                    <a href="#CallBack" data-bs-toggle="modal" className="btn btn-thm2 mt-0" title="Request a Call Back">Request a Call Back</a>
                                                    <a href="#SendInquiry" data-bs-toggle="modal" className="btn btn-thm mt-0 ms-3" title="Send Inquiry">Get a Quotes</a>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 text-center">
                                                <ul className="m-0 p-0 CValues ms-xxl-5 ms-lg-4">
                                                    {
                                                        specialitydata.map((speciality, i) =>
                                                            <li key={i}>
                                                                <div>
                                                                    {
                                                                        helpers.helpers1(speciality.image) ?
                                                                            <picture>
                                                                                <img loading="lazy" src={process.env.REACT_APP_API + 'uploads/specialty/' + speciality.image} alt={speciality.title} width="80" height="80" />
                                                                            </picture>
                                                                            :
                                                                            <picture>
                                                                                <source srcSet={process.env.REACT_APP_API + 'uploads/specialty/avif/' + speciality.image + '.avif'} type="image/avif" />
                                                                                <source srcSet={process.env.REACT_APP_API + 'uploads/specialty/webp/' + speciality.image + '.webp'} type="image/webp" />
                                                                                <img loading="lazy" src={process.env.REACT_APP_API + 'uploads/specialty/jpg/' + speciality.image + '.jpg'} alt={speciality.title} width="80" height="80" />
                                                                            </picture>
                                                                    }

                                                                    <h3 className="h6">{speciality.title}</h3>
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </>
                                        : ''
                                }

                            </div>
                            <div className="d-lg-none text-center">
                                <a href="#CallBack" data-bs-toggle="modal" className="btn btn-thm2 mt-lg-0" title="Request a Call Back">Request a Call Back</a>
                                <a href="#SendInquiry" data-bs-toggle="modal" className="btn btn-thm mt-lg-0 ms-sm-3" title="Send Inquiry">Get a Quotes</a>
                            </div>
                        </div>
                    </section>
                    :
                    ''
            }

            <div className="modal fade PopupM" id="SendInquiry" tabIndex="-1" aria-labelledby="SendInquiryLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <form onSubmit={QuoteSubmit} className="modal-content">
                        <div className="modal-header border-0 d-none">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body pt-4 px-md-4">
                            <h3 className="fw-bold h4 thm">Get a Quotes</h3>
                            <p>fill out the form below and resolve your problems.</p>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="gname" value={quotename} placeholder="Name" onChange={(e) => setQuoteName(e.target.value)} />
                                        <label htmlFor="gname" className="form-label">Name<span className="text-danger">*</span></label>
                                        {quoteerror.name ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{quoteerror.name}</span> : ''}

                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="email" className="form-control" id="gemail" value={quoteemail} placeholder="Email Address" onChange={(e) => setQuoteEmail(e.target.value)} />
                                        <label htmlFor="gemail" className="form-label">Email Address<span className="text-danger">*</span></label>
                                        {quoteerror.email ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{quoteerror.email}</span> : ''}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="number" className="form-control" id="gmobile" value={quotephone} placeholder="Contact Number" onChange={(e) => setQuotePhone(e.target.value)} />
                                        <label htmlFor="gmobile" className="form-label">Contact Number<span className="text-danger">*</span></label>
                                        {quoteerror.phone ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{quoteerror.phone}</span> : ''}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-0">
                                        <select className="form-control form-select" id="response" value={quoteresponse} onChange={(e) => setQuoteResponse(e.target.value)}>
                                            <option value="">Response Type</option>
                                            <option value="email">Email ID</option>
                                            <option value="phone-call">Phone Call</option>
                                            <option value="text-message">Text Message</option>
                                        </select>
                                        <label htmlFor="response" className="form-label">How would you want to get a response<span className="text-danger">*</span></label>
                                    </div>
                                    {quoteerror.response ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{quoteerror.response}</span> : ''}
                                </div>
                                <div className="col-12">
                                    <div className="form-floating mb-3">
                                        <textarea className="form-control" id="problems" onChange={(e) => setQuoteproblem(e.target.value)} value={quoteproblem} placeholder="Your Problem"></textarea>
                                        <label htmlFor="problems" className="form-label">Your Problem<span className="text-danger">*</span></label>
                                        {quoteerror.problem ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{quoteerror.problem}</span> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer pt-3 border-top pb-4 d-block text-center">
                            <button type="button" className="btn btn-thm3" data-bs-dismiss="modal">Close</button>
                            {
                                btnloader ?
                                    <button type="button" className="btn btn-thm disabled"><FontAwesomeIcon icon={faSpinner} className="fa-spin me-1" /> Loading...</button>
                                    :
                                    <button className="btn btn-thm">Submit Now</button>
                            }
                        </div>
                    </form>
                </div>
            </div>
            <div className="modal fade PopupM" id="CallBack" tabIndex="-1" aria-labelledby="CallBackLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <form onSubmit={CallBackSubmit} className="modal-content">
                        <div className="modal-header border-0 d-none">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body pt-4 px-md-4">
                            <h3 className="fw-bold h4 thm">Request a Call Back</h3>
                            <p>Fill out the form below and Call Back <strong>Bird Electrical Solutions</strong> team.</p>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="name" value={callbackname} placeholder="Name" onChange={(e) => setCallBackName(e.target.value)} />
                                        <label htmlFor="name" className="form-label">Name<span className="text-danger">*</span></label>
                                        {callbackerror.name ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{callbackerror.name}</span> : ''}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="email" value={callbackemail} placeholder="Email Address" onChange={(e) => setCallBackEmail(e.target.value)} />
                                        <label htmlFor="email" className="form-label">Email Address<span className="text-danger">*</span></label>
                                        {callbackerror.email ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{callbackerror.email}</span> : ''}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating mb-3">
                                        <input type="number" className="form-control" id="mobile" value={callbackphone} placeholder="Contact Number" onChange={(e) => setCallBackPhone(e.target.value)} />
                                        <label htmlFor="mobile" className="form-label">Contact Number<span className="text-danger">*</span></label>
                                        {callbackerror.phone ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{callbackerror.phone}</span> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer pt-3 border-top pb-4 d-block text-center">
                            <button type="button" className="btn btn-thm3" data-bs-dismiss="modal">Close</button>
                            {
                                btnloader ?
                                    <button type="button" className="btn btn-thm disabled"><FontAwesomeIcon icon={faSpinner} className="fa-spin me-1" /> Loading...</button>
                                    :
                                    <button className="btn btn-thm">Submit Now</button>
                            }
                        </div>
                    </form>
                </div>
            </div>

        </>
    )
}
