import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../css/home.css';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Speciality from './Speciality';
import axios from 'axios';
import Meta from './Meta';
import Getintouch from './layouts/Getintouch';
import helpers from '../Helper';
import SliderLoder from '../loader/home/Slider';
import AboutLoder from '../loader/About';
import SerLoder from '../loader/Services';
import BlogLoder from '../loader/Blog';
export default function Home() {
    const [aboutdata, setcmsdata] = useState();
    const [boxaboutdata, setboxaboutdata] = useState();
    const [servicecmsdata, setservicecmsdata] = useState([]);
    const [categories, setCategories] = useState([]);
    const [contactdata, setcontactdata] = useState([]);
    const [lists, setlists] = useState([]);
    const [testimonials, settestimonial] = useState([]);
    const [testcmsdata, settestcmsdata] = useState([]);
    const [blogdata, setblogdata] = useState([]);
    const [blogcmsdata, setblogcmsdata] = useState([]);
    const [banners, setBanner] = useState([]);
    const [loader, setLoader] = useState(true);
    const [aboutloader, setAboutLoader] = useState(true);
    const [serloader, setSerLoader] = useState(true);
    const [blogloader, setBlogLoader] = useState(true);

    const fetchData = async () => {
        await axios.get(process.env.REACT_APP_API + 'api/banners').then((responose) => {
            setBanner(responose.data.data);
            setLoader(false);
        });
    }
    const ServiceCmsData = async () => {
        await axios.get(process.env.REACT_APP_API + 'api/getcms/16').then((response) => {
            setservicecmsdata(response.data.data);
        });
    }
    const ServiceCategoryData = async () => {
        await axios.get(process.env.REACT_APP_API + 'api/service-category/').then((response) => {
            setCategories(response.data.data.data);
        });
    }
    const AboutData = async () => {
        await axios.get(process.env.REACT_APP_API + 'api/getcms/2').then((response) => {
            setcmsdata(response.data.data);
            setAboutLoader(false);
        });
    }
    const AboutBoxData = async () => {
        await axios.get(process.env.REACT_APP_API + 'api/getcms/14').then((response) => {
            setboxaboutdata(response.data.data);
        });
    }
    const ContactData = async () => {
        await axios.get(process.env.REACT_APP_API + 'api/getcms/4').then((responose) => {
            setcontactdata(responose.data.data);
            setSerLoader(false);
        });
    }
    const ContactListData = async () => {
        await axios.get(process.env.REACT_APP_API + 'api/contacts').then((responose) => {
            setlists(responose.data.data.data);
        });
    }
    const TestimonialData = async () => {
        await axios.get(process.env.REACT_APP_API + 'api/testimonials').then((responose) => {
            settestimonial(responose.data.data.data);
        });
    }
    const TestimonialCmsData = async () => {
        await axios.get(process.env.REACT_APP_API + 'api/getcms/15').then((response) => {
            settestcmsdata(response.data.data);
        });
    }
    const getblogdata = async () => {
        await axios.get(process.env.REACT_APP_API + 'api/blogs?type=topblogs').then((responose) => {
            setblogdata(responose.data.data.data);
            setBlogLoader(false);
        });
    }
    const getblogcmsdata = async () => {
        await axios.get(process.env.REACT_APP_API + 'api/getcms/17').then((response) => {
            setblogcmsdata(response.data.data);
        });
    }

    useEffect(() => {
        setLoader(true);
        fetchData();
    }, [setBanner]);

    useEffect(() => {
        ServiceCmsData();
        ServiceCategoryData();
    }, [setCategories,]);

    useEffect(() => {
        setAboutLoader(true);
        AboutData();
        AboutBoxData();
    }, [setboxaboutdata]);

    useEffect(() => {
        setSerLoader(true);
        ContactListData();
        ContactData();
    }, [setlists]);

    useEffect(() => {
        TestimonialData();
        TestimonialCmsData();
    }, [settestimonial]);

    useEffect(() => {
        setBlogLoader(true);
        getblogdata();
        getblogcmsdata();
    }, [setblogdata]);


    return (
        <>
            <Meta id={1} />
            <main>
                {
                    loader ?
                        <SliderLoder/>
                        :

                        banners.length > 0 ?
                            <section className="Slider pb-0 bs">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 150"><path fill="var(--thm)" d="M1600,82.42s-107.75-175-500-15c-436.7,178.11-853.54,10-962,10S17.91,99.69,0,109.42c.06,0,0,37,0,37H1600Z" /><path fill="var(--white)" d="M1600,118.42s-94.07-190.17-470-54c-458.06,165.92-926,27-1042,27-42.61,0-70.09,7.27-88,17,.06,0,0,42,0,42H1600Z" /></svg>
                                <Carousel fade nextIcon="" prevIcon="" interval={5000}>
                                    {
                                        banners.map((banner, br) =>
                                            <Carousel.Item key={br}>
                                                {
                                                    helpers.helpers1(banner.image) ?
                                                        <picture>
                                                            <img src={process.env.REACT_APP_API + 'uploads/banner/' + banner.image} alt={banner.image_alt} width="1400" height="520" />
                                                        </picture>
                                                        :
                                                        <picture>
                                                            <source srcSet={process.env.REACT_APP_API + 'uploads/banner/webp/' + banner.image + '.webp'} type="image/webp" />
                                                            <source srcSet={process.env.REACT_APP_API + 'uploads/banner/avif/' + banner.image + '.avif'} type="image/avif" />
                                                            <img src={process.env.REACT_APP_API + 'uploads/banner/jpg/' + banner.image + '.jpg'} alt={banner.image_alt} width="1400" height="520" />
                                                        </picture>
                                                }
                                                <Carousel.Caption className='d-md-flex'>
                                                    <div className='container'>
                                                        <div className="row justify-content-center mt-5">
                                                            <div className="col-lg-7 col-md-8 text-center">
                                                                <h2 className="title h1 text-u">
                                                                    {banner.image_alt ? helpers.sepratestring(banner.image_alt)[0] : ''} <span>{banner.image_alt ? helpers.sepratestring(banner.image_alt)[1] : ''}</span></h2>
                                                                <span dangerouslySetInnerHTML={{ __html: banner.description }} />
                                                                {
                                                                    banner.link !== '' && banner.link !== '#' ?
                                                                        <a href={banner.link} className="btn btn-thm">View Detail</a>
                                                                        : ''
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Carousel.Caption>
                                            </Carousel.Item>
                                        )}
                                </Carousel>
                            </section>
                            :
                            ''
                }

                {
                    aboutloader ?
                        <AboutLoder/>
                        :
                        aboutdata ?
                            <section className="Sec1">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600.25 649.4"><path d="M1183.91,16.92C864.28,127.69,798,447.9,729.25,511.4c-110.9,102.5-294.9,68.5-350,41s-227-81.5-312-62c-75,18.5-67,66-67,66v93h1600L1600,54.9S1339.24-36.9,1183.91,16.92Z" /></svg>
                                <div className="container">
                                    <div className="text-center mb-4">
                                        {
                                            aboutdata.title ? <span className="SubTitle">{aboutdata.title}</span> : ''
                                        }

                                        <h2 className="Heading">
                                            {aboutdata.heading ? helpers.sepratestring(aboutdata.heading)[0] : ''} <span>{aboutdata.heading ? helpers.sepratestring(aboutdata.heading)[1] : ''}</span>
                                        </h2>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6 order-lg-last text-center">
                                            <div className="ms-lg-5 mb-4">
                                                {
                                                    helpers.helpers1(aboutdata.image) ?
                                                        <picture>
                                                            <img loading="lazy" src={process.env.REACT_APP_API + 'uploads/cms/' + aboutdata.image} alt={aboutdata.title} width="700" height="600" />
                                                        </picture>
                                                        :
                                                        <picture>
                                                            <source srcSet={process.env.REACT_APP_API + 'uploads/cms/avif/' + aboutdata.image + '.avif'} type="image/avif" />
                                                            <source srcSet={process.env.REACT_APP_API + 'uploads/cms/webp/' + aboutdata.image + '.webp'} type="image/webp" />
                                                            <img loading="lazy" src={process.env.REACT_APP_API + 'uploads/cms/jpg/' + aboutdata.image + '.jpg'} alt={aboutdata.title} width="700" height="600" />
                                                        </picture>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <span dangerouslySetInnerHTML={{ __html: aboutdata.description }} />
                                            <div className='mt-4 text-center text-lg-start'><Link to="/about" className="btn btn-thm">Read More</Link></div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            : ''
                }

                {
                    boxaboutdata ?
                        <section className="bgimg">
                            <picture>
                                <source srcSet={require("../assets/img/bg-img.webp")} type="image/webp" />
                                <img loading="lazy" src={require("../assets/img/bg-img.jpg")} alt="We Need to" className="imgbg" width="1600" height="900" />
                            </picture>
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-md-8 text-center">
                                        {boxaboutdata.title ? <span className="SubTitle text-white">{boxaboutdata.title}</span> : ''}

                                        <h2 className="Heading text-white">
                                            {boxaboutdata.heading ? helpers.sepratestring(boxaboutdata.heading)[0] : ''} <span>{boxaboutdata.heading ? helpers.sepratestring(boxaboutdata.heading)[1] : ''}</span>
                                        </h2>
                                        <div className='text-white' dangerouslySetInnerHTML={{ __html: boxaboutdata.description }} />
                                        <a href="#SendInquiry" data-bs-toggle="modal" className="btn btn-thm bg-black">Let's Talk <img src={require("../assets/img/arrow1-w.svg").default} width="24" height="24" alt="arrow" /></a>
                                    </div>
                                </div>
                            </div>
                        </section>
                        : ''
                }


                {
                    serloader ?
                        <SerLoder/>
                        :
                        categories.length > 0 ?
                            <section className="Sec3 MyCarouselSec Services Home">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 783.89" fill="var(--white)"><path d="M0,783.89S26.21,629,236,673.89c316.5,67.61,242.5-92,468-180.07,235.51-92,311.49,38,449.22-6.8C1251.61,455,1231,287.83,1412,303.83c67.48,6,188-80,188-80V0H0Z"></path></svg>

                                <div className="container">
                                    <div className="row mb-4 justify-content-center">
                                        <div className="col-lg-9 col-md-10 text-center">
                                            {servicecmsdata.title ? <span className="SubTitle">{servicecmsdata.title}</span> : ''}
                                            <h2 className="Heading">
                                                {servicecmsdata.heading ? helpers.sepratestring(servicecmsdata.heading)[0] : ''} <span>{servicecmsdata.heading ? helpers.sepratestring(servicecmsdata.heading)[1] : ''}</span>
                                            </h2>
                                            <div dangerouslySetInnerHTML={{ __html: servicecmsdata.description }} />
                                        </div>
                                    </div>
                                    <div className="row SerTab">
                                        <div className="col-xl-3 col-md-4">
                                            <ul className="nav nav-pills grey m-0 p-0 text-center w-100">
                                                {
                                                    categories.map((cat, e) =>
                                                        <li key={e} className={e === 0 ? "nav-item active" : "nav-item"} role="presentation">
                                                            <a className={e === 0 ? "nav-link active" : "nav-link"} id={"Multi-tab" + e} data-bs-toggle="pill" href={"#Multi" + e} role="button">
                                                                {
                                                                    helpers.helpers1(cat.icon) ?
                                                                        <img loading="lazy" src={process.env.REACT_APP_API + 'uploads/service/category/icon/' + cat.icon} alt={cat.title} width="80" height="80" />
                                                                        :
                                                                        <img loading="lazy" src={process.env.REACT_APP_API + 'uploads/service/category/icon/jpg/' + cat.icon + '.jpg'} alt={cat.title} width="80" height="80" />
                                                                }
                                                                <h3 className="h6 text-black">{cat.title}</h3>
                                                            </a>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                        <div className="col-lg-9 col-md-8">
                                            <div className="tab-content">
                                                {
                                                    categories.map((cat, e) =>
                                                        <div key={e} className={e === 0 ? "tab-pane p-0 fade show active" : "tab-pane p-0 fade"} id={"Multi" + e} role="tabpanel" aria-labelledby="Multi-tab" tabIndex="0">
                                                            <div className='row justify-content-center'>
                                                                {
                                                                    cat?.services?.length > 0 ?
                                                                        <>
                                                                            {
                                                                                cat.services.map((services, s) =>
                                                                                    <div key={s} className='col-lg-3 col-sm-4 col-6 mb-4'>
                                                                                        <Link to={'/service/' + services.alias} className="card ProBlock SerBlock">
                                                                                            <div className="card-header">
                                                                                                {
                                                                                                    helpers.helpers1(services.image) ?
                                                                                                        <picture>
                                                                                                            <img loading="lazy" src={process.env.REACT_APP_API + 'uploads/service/' + services.image} alt={services.title} width="121" height="131" />
                                                                                                        </picture>
                                                                                                        :
                                                                                                        <picture>
                                                                                                            <source srcSet={process.env.REACT_APP_API + 'uploads/service/webp/' + services.image + '.webp'} type="image/webp" />
                                                                                                            <source srcSet={process.env.REACT_APP_API + 'uploads/service/avif/' + services.image + '.avif'} type="image/avif" />
                                                                                                            <img loading="lazy" src={process.env.REACT_APP_API + 'uploads/service/jpg/' + services.image + '.jpg'} alt={services.title} width="121" height="131" />
                                                                                                        </picture>
                                                                                                }
                                                                                            </div>
                                                                                            <div className="card-body text-center px-2">
                                                                                                <h3 className="h5">{services.title}</h3>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </>
                                                                        :
                                                                        <div className='text-center mt-5'>
                                                                            <p>No any service are available yet</p>
                                                                        </div>
                                                                }

                                                            </div>
                                                            <div className="PreNext d-lg-none"><span className="btn prev"></span><span className="btn next"></span></div>
                                                            <div className="text-center">
                                                                <Link to={"/services/" + cat.alias} className="btn btn-thm mt-1">View all Services</Link>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            :
                            ''
                }


                <Speciality content={5}/>

                {
                    blogloader ?
                        <BlogLoder/>
                        :
                        blogdata.length > 0 ?
                            <section className="Sec4 MyCarouselSec">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 283.81"><path d="M1600,41.81s-217-137-550,85C784.59,303.75,525,275.58,450,270.31c-72.82-5.12-197.95-53.5-312-53.5-42.61-.32-120.09,22.27-138,32,.06,0,0,35,0,35H1600Z" /></svg>
                                <div className="container">
                                    <div className="row mb-4 justify-content-center">
                                        <div className="col-lg-9 col-md-10 text-center">
                                            {blogcmsdata.title ? <span className="SubTitle">{blogcmsdata.title}</span> : ''}

                                            <h2 className="Heading">
                                                {blogcmsdata.heading ? helpers.sepratestring(blogcmsdata.heading)[0] : ''} <span>{blogcmsdata.heading ? helpers.sepratestring(blogcmsdata.heading)[1] : ''}</span>
                                            </h2>
                                            <span dangerouslySetInnerHTML={{ __html: blogcmsdata.description }} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <OwlCarousel className="owl-carousel blog" items={3} margin={30} loop={true} dots={false} nav={false} autoplay={false} autoplayTimeout={2000} autoplayHoverPause={true} responsive={{0:{items:1},350:{items:1},460:{items:2},600:{items:2},768:{items:2},992:{items:2},1200:{items:3},1600:{items:3}}}>
                                                {
                                                    blogdata.map((blog, i) =>
                                                        <Link to={`/blog/${blog.alias}`} key={i} className="card ProBlock">
                                                            <div className="card-header">
                                                                {
                                                                    helpers.helpers1(blog.image) ?
                                                                        <picture>
                                                                            <img loading="lazy" src={process.env.REACT_APP_API + 'uploads/blog/' + blog.image} alt={blog.title} width="121" height="131" />
                                                                        </picture>
                                                                        :
                                                                        <picture>
                                                                            {/* <source srcSet={process.env.REACT_APP_API + 'uploads/blog/avif/' + blog.image + '.avif'} type="image/avif" /> */}
                                                                            <source srcSet={process.env.REACT_APP_API + 'uploads/blog/webp/' + blog.image + '.webp'} type="image/webp" />
                                                                            <img loading="lazy" src={process.env.REACT_APP_API + 'uploads/blog/jpg/' + blog.image + '.jpg'} alt={blog.title} width="121" height="131" />
                                                                        </picture>
                                                                }
                                                            </div>
                                                            <div className="card-body px-0 text-center">
                                                                <span className="badge text-black bgthm1">{moment(blog.post_date).format('DD MMMM YYYY')}</span>
                                                                <h3 className="h5 mt-2">{blog.title}</h3>
                                                            </div>
                                                        </Link>
                                                    )
                                                }
                                            </OwlCarousel>
                                        </div>
                                    </div>
                                    <div className="text-center"><Link to="blogs" className="btn btn-thm">View all Blog</Link></div>
                                </div>
                            </section>
                            : ''

                }

                {
                    testimonials.length > 0 ?
                        <section className="Sec5 grey">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-lg-9 text-center">
                                        {testcmsdata.title ? <span className="SubTitle">{testcmsdata.title}</span> : ''}

                                        <h2 className="Heading">
                                            {testcmsdata.heading ? helpers.sepratestring(testcmsdata.heading)[0] : ''} <span>{testcmsdata.heading ? helpers.sepratestring(testcmsdata.heading)[1] : ''}</span>
                                        </h2>
                                        <div dangerouslySetInnerHTML={{ __html: testcmsdata.description }} />
                                        <OwlCarousel className="owl-carousel testi" items={1} margin={30} loop={true} dots={false} nav={false} autoplay={false} autoplayTimeout={2000} autoplayHoverPause={true}>
                                            {
                                                testimonials.map((testimonial, i) =>
                                                    <div className="testibox testiHome border-0 shadow-0" key={i}>
                                                        <div className="testitext"><div dangerouslySetInnerHTML={{ __html: testimonial.description }} /></div>
                                                        {
                                                            helpers.helpers1(testimonial.image) ?
                                                                <picture>
                                                                    <img loading="lazy" src={process.env.REACT_APP_API + 'uploads/testimonial/' + testimonial.image} alt="improve" width="121" height="131" />
                                                                </picture>
                                                                :
                                                                <picture>
                                                                    <source srcSet={process.env.REACT_APP_API + 'uploads/testimonial/webp/' + testimonial.image + '.webp'} type="image/webp" />
                                                                    <source srcSet={process.env.REACT_APP_API + 'uploads/testimonial/avif/' + testimonial.image + '.avif'} type="image/avif" />
                                                                    <img loading="lazy" src={process.env.REACT_APP_API + 'uploads/testimonial/jpg/' + testimonial.image + '.jpg'} alt="improve" width="121" height="131" />
                                                                </picture>
                                                        }
                                                        <span className="d-block h5">{testimonial.title}</span>
                                                    </div>
                                                )
                                            }
                                        </OwlCarousel >
                                    </div>
                                </div>
                            </div>
                        </section>
                        :
                        ''
                }

                {
                    lists.length > 0 ?

                        <section className="Sec6 Contact">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-9 col-md-10 text-center">
                                        {contactdata.title ? <span className="SubTitle">{contactdata.title}</span> : ''}
                                        <h2 className="Heading">
                                            {contactdata.heading ? helpers.sepratestring(contactdata.heading)[0] : ''} <span>{contactdata.heading ? helpers.sepratestring(contactdata.heading)[1] : ''}</span>
                                        </h2>
                                        <div dangerouslySetInnerHTML={{ __html: contactdata.description }} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4">
                                        {
                                            contactdata.image ?
                                                <>
                                                    {
                                                        helpers.helpers1(contactdata.image) ?
                                                            <picture>
                                                                <img loading="lazy" src={process.env.REACT_APP_API + 'uploads/cms/' + contactdata.image} alt="24/7 Emergency Service" className="w-100" width="750" height="400" />
                                                            </picture>
                                                            :
                                                            <picture>
                                                                <source srcSet={process.env.REACT_APP_API + 'uploads/cms/avif/' + contactdata.image + '.avif'} type="image/avif" />
                                                                <source srcSet={process.env.REACT_APP_API + 'uploads/cms/webp/' + contactdata.image + '.webp'} type="image/webp" />
                                                                <img loading="lazy" src={process.env.REACT_APP_API + 'uploads/cms/jpg/' + contactdata.image + '.jpg'} alt="24/7 Emergency Service" className="w-100" width="750" height="400" />
                                                            </picture>
                                                    }
                                                </>
                                                :
                                                ''
                                        }

                                        {
                                            lists.map((list, i) =>
                                                <div className="bgthm ConInfo mb-3" key={i}>
                                                    <ul>
                                                        <li><img src={require("../assets/img/location-w.svg").default} alt="location" /> <span><strong>{list.title}</strong><br />{list.address}</span></li>
                                                        {
                                                            list.phone ?
                                                                <li><img src={require("../assets/img/call-w.svg").default} alt="call" /> <a href={'tel:' + list.phone}>{list.phone}</a></li>
                                                                :
                                                                ''
                                                        }
                                                        {
                                                            list.email ?
                                                                <li><img src={require("../assets/img/mail-w.svg").default} alt="mail" /> <a href={'mailto:' + list.email}>{list.email}</a></li>
                                                                :
                                                                ''
                                                        }
                                                    </ul>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="col-lg-8">
                                        <Getintouch />
                                    </div>
                                </div>
                            </div>
                        </section>
                        : ''
                }
            </main>
        </>
    )
}
