import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../css/blog.css";
import "../assets/css/blog.css";
import PageLoader from "../loader/Cms";
import moment from "moment/moment";
import { SocialIcon } from "react-social-icons";
import { toast } from "react-toastify";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import helpers from "../Helper";
import Page404 from "./layouts/Page404";

export default function Blogdetail() {
  const params = useParams();
  const [blogdtl, setblogdtl] = useState([]);
  const [topblogs, settopblogs] = useState([]);
  const [categories, setcategories] = useState([]);
  const [relatieslist, setrelatieslist] = useState([]);
  const [dataNotFound, setDataNotFound] = useState(false);

  const [btnloader, setBtnloader] = useState(false);
  const [newsletter, setNewsletter] = useState("");
  const [pageloader, setLoadingrtPro] = useState(false);

  const getblogdtl = async () => {
    await axios
      .get(process.env.REACT_APP_API + "api/blogs/" + params.alias)
      .then((response) => {
        setblogdtl(response.data.data);
        getrelaties(response.data.data.category_id);
        setLoadingrtPro(false);
      })
      .catch((err) => {
        setDataNotFound(!dataNotFound);
      });
  };

  useEffect(() => {
    setLoadingrtPro(true);
    getblogdtl();
    // eslint-disable-next-line
  }, [params]);

  const gettopblogs = async () => {
    await axios
      .get(process.env.REACT_APP_API + "api/topblog?current=" + params.alias)
      .then((response) => {
        settopblogs(response.data.data.data);
      });
  };

  useEffect(() => {
    gettopblogs();
    // eslint-disable-next-line
  }, [settopblogs]);

  const getcategories = async () => {
    await axios
      .get(process.env.REACT_APP_API + "api/blog-category")
      .then((response) => {
        setcategories(response.data.data.data);
      });
  };

  useEffect(() => {
    getcategories();
  }, [setcategories]);

  async function getrelaties(category) {
    await axios
      .get(
        process.env.REACT_APP_API +
          "api/relatedblog/" +
          category +
          "?current=" +
          params.alias
      )
      .then((response) => {
        setrelatieslist(response.data.data.data);
      });
  }

  // async function checkimagetype(image) {
  //     if (image) {
  //         let imageArr = image.split('.');
  //         imageArr = imageArr.slice(-1);
  //         if (imageArr === 'svg' || imageArr === 'webp' || imageArr === 'avif') {
  //             return true;
  //         } else {
  //             return false;
  //         }
  //     } else {
  //         return false;
  //     }
  // }

  async function SubmitNewsletter(e) {
    e.preventDefault();
    setBtnloader(true);
    let callback = { email: newsletter };
    await axios
      .post(process.env.REACT_APP_API + "api/newsletter/", callback)
      .then((responose) => {
        toast.success(responose.data.message);
        setBtnloader(false);
        setNewsletter("");
      })
      .catch((errors) => {
        let error = errors.response.data;
        toast.error(error.message);
        setBtnloader(false);
      });
  }

  return (
    <>
      {dataNotFound ? (
        <Page404 />
      ) : (
        <>
          <Helmet>
            <title>{blogdtl.meta_title}</title>
            <meta name="description" content={blogdtl.meta_description} />
            <meta name="keywords" content={blogdtl.meta_keywords} />
          </Helmet>
          <main>
            <section className="InnerHead bg-black">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 150">
                <path
                  fill="var(--thm)"
                  d="M1600,82.42s-107.75-175-500-15c-436.7,178.11-853.54,10-962,10S17.91,99.69,0,109.42c.06,0,0,37,0,37H1600Z"
                />
                <path
                  fill="var(--white)"
                  d="M1600,118.42s-94.07-190.17-470-54c-458.06,165.92-926,27-1042,27-42.61,0-70.09,7.27-88,17,.06,0,0,42,0,42H1600Z"
                />
              </svg>
              <picture>
                <source
                  srcSet={require("../assets/img/banner1.webp")}
                  type="image/webp"
                />
                <img
                  src={require("../assets/img/banner1.jpg")}
                  className="imgbg"
                  alt="Blog Details"
                  width="1400"
                  height="520"
                />
              </picture>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-9 text-center">
                    <h1 className="fw-normal text-white">{blogdtl.title}</h1>
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <img
                            src={require("../assets/img/home.svg").default}
                            width="14"
                            height="14"
                            alt="Home"
                          />
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/blogs">Blog</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <span>Blog Details</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
            <section className="BlogDetail BlogCategory">
              {pageloader ? (
                <PageLoader />
              ) : (
                <div className="container">
                  <div className="row">
                    <div className="col-lg-9 col-md-8 BlogList">
                      <div className="DetailTop mb-4">
                        {helpers.helpers1(blogdtl.banner) ? (
                          <picture>
                            <img
                              loading="lazy"
                              src={
                                process.env.REACT_APP_API +
                                "uploads/blog/banner/" +
                                blogdtl.banner
                              }
                              alt="24/7 Emergency Service"
                              className="h-auto float-md-end mb-md-5 ms-md-5 col-md-3"
                              width="200"
                              height="131"
                            />
                          </picture>
                        ) : (
                          <picture>
                            {/* <source srcSet={process.env.REACT_APP_API + 'uploads/blog/banner/avif/' + blogdtl.banner + '.avif'} type="image/avif" /> */}
                            <source
                              srcSet={
                                process.env.REACT_APP_API +
                                "uploads/blog/banner/webp/" +
                                blogdtl.banner +
                                ".webp"
                              }
                              type="image/webp"
                            />
                            <img
                              loading="lazy"
                              src={
                                process.env.REACT_APP_API +
                                "uploads/blog/banner/jpg/" +
                                blogdtl.banner +
                                ".jpg"
                              }
                              alt="24/7 Emergency Service"
                              className="h-auto float-md-end mb-md-5 ms-md-5 col-md-3"
                              width="200"
                              height="131"
                            />
                          </picture>
                        )}
                        <h1 className="mt-4 h3">{blogdtl.title}</h1>
                        <div className="d-flex mt-2">
                          <a href="/#">
                            <i className="fal fa-user-circle thm"></i> Admin
                          </a>{" "}
                          |
                          <span>
                            <i className="far fa-calendar-alt thm"></i>{" "}
                            {moment(blogdtl.post_date).format("DD MMMM YYYY")}
                          </span>
                        </div>
                      </div>
                      <div
                        className="CmsPage mt-3"
                        dangerouslySetInnerHTML={{
                          __html: blogdtl.description,
                        }}
                      ></div>
                      <div className="sharebox mt-4">
                        <div className="row justify-content-between">
                          <div className="col">
                            <strong>Share Now</strong>
                            <div id="social-links">
                              <ul>
                                <SocialIcon
                                  url=""
                                  className="me-1"
                                  network="facebook"
                                  style={{ height: 30, width: 30 }}
                                />
                                <SocialIcon
                                  url=""
                                  className="me-1"
                                  network="twitter"
                                  style={{ height: 30, width: 30 }}
                                />
                                <SocialIcon
                                  url=""
                                  className="me-1"
                                  network="linkedin"
                                  style={{ height: 30, width: 30 }}
                                />
                                <SocialIcon
                                  url=""
                                  className="me-1"
                                  network="telegram"
                                  style={{ height: 30, width: 30 }}
                                />
                                <SocialIcon
                                  url=""
                                  className="me-1"
                                  network="whatsapp"
                                  style={{ height: 30, width: 30 }}
                                />
                                <SocialIcon
                                  url=""
                                  network="reddit"
                                  style={{ height: 30, width: 30 }}
                                />
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      {relatieslist.length > 0 ? (
                        <div className="BlogSec4 mt-4 border-top">
                          <h3 className="h4 thm">Related Articles</h3>
                          <OwlCarousel
                            className="owl-carousel blog"
                            items={3}
                            margin={30}
                            loop={false}
                            dots={false}
                            nav={false}
                            autoplay={false}
                            autoplayTimeout={2000}
                            autoplayHoverPause={true}
                            responsive={{
                              0: { items: 1 },
                              350: { items: 1 },
                              460: { items: 2 },
                              600: { items: 2 },
                              768: { items: 2 },
                              992: { items: 2 },
                              1200: { items: 3 },
                              1600: { items: 3 },
                            }}
                          >
                            {relatieslist.map((relatieslist, r) => (
                              <Link
                                key={r}
                                to={`/blog/` + relatieslist.alias}
                                className="card"
                              >
                                <div className="card-header">
                                  {helpers.helpers1(relatieslist.image) ? (
                                    <picture>
                                      <img
                                        loading="lazy"
                                        src={
                                          process.env.REACT_APP_API +
                                          "uploads/blog/" +
                                          relatieslist.image
                                        }
                                        alt={relatieslist.title}
                                        width="121"
                                        height="131"
                                      />
                                    </picture>
                                  ) : (
                                    <picture>
                                      {/* <source srcSet={process.env.REACT_APP_API + 'uploads/blog/avif/' + relatieslist.image + '.avif'} type="image/avif" /> */}
                                      <source
                                        srcSet={
                                          process.env.REACT_APP_API +
                                          "uploads/blog/webp/" +
                                          relatieslist.image +
                                          ".webp"
                                        }
                                        type="image/webp"
                                      />
                                      <img
                                        loading="lazy"
                                        src={
                                          process.env.REACT_APP_API +
                                          "uploads/blog/jpg/" +
                                          relatieslist.image +
                                          ".jpg"
                                        }
                                        alt={relatieslist.title}
                                        width="121"
                                        height="131"
                                      />
                                    </picture>
                                  )}
                                </div>
                                <div className="card-body px-0 text-center">
                                  <span className="badge text-black bgthm1">
                                    {moment(relatieslist.post_date).format(
                                      "DD MMMM YYYY"
                                    )}
                                  </span>
                                  <h3 className="h5 mt-2">
                                    {relatieslist.title}
                                  </h3>
                                </div>
                              </Link>
                            ))}
                          </OwlCarousel>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-12 col-md-4 col-lg-3 rightp">
                      <div>
                        {topblogs.length > 0 ? (
                          <>
                            <h3>Most Popular Blogs</h3>
                            <div className="card MostBlogs">
                              <div className="card-body">
                                {topblogs.map((topblog, a) => (
                                  <div key={a}>
                                    <div className="img">
                                      {helpers.helpers1(topblog.image) ? (
                                        <picture>
                                          <img
                                            loading="lazy"
                                            src={
                                              process.env.REACT_APP_API +
                                              "uploads/blog/" +
                                              topblog.image
                                            }
                                            alt={topblog.title}
                                            width="200"
                                            height="200"
                                          />
                                        </picture>
                                      ) : (
                                        <picture>
                                          {/* <source srcSet={process.env.REACT_APP_API + 'uploads/blog/avif/' + topblog.image + '.avif'} type="image/avif" /> */}
                                          <source
                                            srcSet={
                                              process.env.REACT_APP_API +
                                              "uploads/blog/webp/" +
                                              topblog.image +
                                              ".webp"
                                            }
                                            type="image/webp"
                                          />
                                          <img
                                            loading="lazy"
                                            src={
                                              process.env.REACT_APP_API +
                                              "uploads/blog/jpg/" +
                                              topblog.image +
                                              ".jpg"
                                            }
                                            alt={topblog.title}
                                            width="200"
                                            height="200"
                                          />
                                        </picture>
                                      )}
                                    </div>
                                    <div className="text">
                                      <h3>
                                        <Link to={"/blog/" + topblog.alias}>
                                          {topblog.title}
                                        </Link>
                                      </h3>
                                      <div className="d-flex">
                                        <a href="/#">
                                          <i className="fal fa-user-circle"></i>{" "}
                                          Admin
                                        </a>{" "}
                                        |{" "}
                                        <span>
                                          <i className="far fa-calendar-alt"></i>{" "}
                                          {moment(topblog.post_date).format(
                                            "DD MMMM YYYY"
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        <h3>Newsletter</h3>
                        <div className="card Newsletter">
                          <form
                            onSubmit={SubmitNewsletter}
                            className="card-body"
                          >
                            <div className="form-group">
                              <label htmlFor="name" className="form-label">
                                Email Address*
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                value={newsletter}
                                onChange={(e) => setNewsletter(e.target.value)}
                                placeholder="Email "
                              />
                            </div>
                            <div className="text-center">
                              {btnloader ? (
                                <button
                                  type="button"
                                  disabled
                                  className="btn btn-thm btn-sm mt-3"
                                  id="searchbox"
                                >
                                  <FontAwesomeIcon
                                    icon={faSpinner}
                                    className="fa-spin me-1"
                                  />{" "}
                                  Loading...
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn btn-thm btn-sm mt-3"
                                >
                                  <span>Subscribe</span>
                                </button>
                              )}
                            </div>
                          </form>
                        </div>
                        {categories.length > 0 ? (
                          <>
                            <h3>Categories</h3>
                            <div className="card MostBlogs">
                              <div className="card-body Archives">
                                <ul className="list-group list-group-flush">
                                  {categories.map((categories, k) => (
                                    <li key={k} className="list-group-item">
                                      <Link
                                        to={
                                          "/blog-catgeory/" + categories.alias
                                        }
                                      >
                                        <i className="fal fa-arrow-right me-2"></i>{" "}
                                        {categories.title}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </section>
          </main>
        </>
      )}
    </>
  );
}
