import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import '../css/privacy.css';
import Meta from './Meta';
import CmsLoader from '../loader/Cms';
import helpers from '../Helper';
export default function Terms() {
    const [cmsdata, setcmsdata] = useState([]);
    const [loader, setLoadingrtPro] = useState(false);
    async function getdata() {
        await axios.get(process.env.REACT_APP_API+'api/getcms/9').then((response) => {
            setcmsdata(response.data.data);
            setLoadingrtPro(false);
        });
    }
    useEffect(() => {
        setLoadingrtPro(true);
        getdata();
    },[]);

    return (
        <>
            <Meta id={9} />
            <main>
                <section className="InnerHead bg-black">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 150"><path fill="var(--thm)" d="M1600,82.42s-107.75-175-500-15c-436.7,178.11-853.54,10-962,10S17.91,99.69,0,109.42c.06,0,0,37,0,37H1600Z" /><path fill="var(--white)" d="M1600,118.42s-94.07-190.17-470-54c-458.06,165.92-926,27-1042,27-42.61,0-70.09,7.27-88,17,.06,0,0,42,0,42H1600Z" /></svg>
                    <picture>
                        <source srcSet={require("../assets/img/banner1.webp")} type="image/webp" />
                        <img src={require("../assets/img/banner1.jpg")} className="imgbg" alt="FAQs" width="1400" height="520" />
                    </picture>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9 text-center">
                                <h1 className="fw-normal text-white">Terms & Conditions</h1>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/"><img src={require("../assets/img/home.svg").default} width="14" height="14" alt="Home" /></Link></li>
                                    <li className="breadcrumb-item"><span>Terms & Conditions</span></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        {
                            loader ?
                                <CmsLoader />
                                :
                                <div className="row">
                                    <div className="col-12 text-center">
                                        {
                                            cmsdata.title ?
                                                <span className="SubTitle">{cmsdata.title}</span>
                                                :
                                                ''
                                        }
                                        <h2 className="Heading">
                                            {cmsdata.heading ? helpers.sepratestring(cmsdata.heading)[0] : ''} <span>{cmsdata.heading ? helpers.sepratestring(cmsdata.heading)[1] : ''}</span>
                                        </h2>
                                    </div>
                                    <div className="col-12 CmsPage" dangerouslySetInnerHTML={{ __html: cmsdata.description }} />
                                </div>
                        }

                    </div>
                </section>
            </main>
        </>
    )
}
