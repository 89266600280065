import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import helpers from "../Helper";
import CmsLoader from "../loader/Cms1";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Page404 from "./layouts/Page404";

export default function ServicesDetail() {
  const parms = useParams();
  const [serviceNotFound,setServiceNotFound] = useState(false);
  const [service, setService] = useState([]);
  const [categories, setCategorieslist] = useState([]);
  const [loader, setLoader] = useState(false);
  const getService = async () => {
    await axios
      .get(process.env.REACT_APP_API + "api/servicedtl/" + parms?.alias)
      .then((response) => {
        setService(response.data.data);
        setLoader(false);
        if (response.data.data) {
          getCategorielists(
            response.data.data.category.alias,
            response.data.data.id
          );
        }
      }).catch((err)=>{
        setServiceNotFound(!serviceNotFound);
      });
  };

  useEffect(() => {
    setLoader(true);
    getService();
    // eslint-disable-next-line
  }, [parms]);

  const getCategorielists = async (category, serviceid) => {
    await axios
      .get(
        process.env.REACT_APP_API +
          "api/services/" +
          category +
          "?service=" +
          serviceid
      )
      .then((response) => {
        setCategorieslist(response.data.data.data);
      });
  };
  return (
    <>
      {serviceNotFound ? (
        <Page404/>
      ) : (
        <>
          <Helmet>
            <title>{service.meta_title ?? ""}</title>
            <meta name="description" content={service.meta_description ?? ""} />
            <meta name="keywords" content={service.meta_keywords ?? ""} />
          </Helmet>
          <main>
            <section className="InnerHead bg-black">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 150">
                <path
                  fill="var(--thm)"
                  d="M1600,82.42s-107.75-175-500-15c-436.7,178.11-853.54,10-962,10S17.91,99.69,0,109.42c.06,0,0,37,0,37H1600Z"
                />
                <path
                  fill="var(--white)"
                  d="M1600,118.42s-94.07-190.17-470-54c-458.06,165.92-926,27-1042,27-42.61,0-70.09,7.27-88,17,.06,0,0,42,0,42H1600Z"
                />
              </svg>
              <picture>
                <source
                  srcSet={require("../assets/img/banner1.webp")}
                  type="image/webp"
                />
                <img
                  src={require("../assets/img/banner1.jpg")}
                  className="imgbg"
                  alt="FAQs"
                  width="1400"
                  height="520"
                />
              </picture>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-9 text-center">
                    <h1 className="fw-normal text-white">
                      {service.title ?? ""}
                    </h1>
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <img
                            src={require("../assets/img/home.svg").default}
                            width="14"
                            height="14"
                            alt="Home"
                          />
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/services">Services</Link>
                      </li>
                      {service.category ? (
                        <li className="breadcrumb-item">
                          <Link to={"/services/" + service.category.alias}>
                            {service.category ? service.category.title : ""}
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      <li className="breadcrumb-item">
                        <span>Services Services</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
            <section className="Home">
              {loader ? (
                <div className="row">
                  <div className="col-12 CmsPage">
                    <CmsLoader />
                  </div>
                </div>
              ) : (
                <div className="container">
                  <div className="row mb-4 justify-content-center">
                    <div className="col-lg-9 col-md-10 text-center">
                      <span className="SubTitle">
                        {service.category ? service.category.title : ""}
                      </span>
                      <h2 className="Heading">
                        {service.title
                          ? helpers.sepratestring(service.title)[0]
                          : ""}{" "}
                        <span>
                          {service.title
                            ? helpers.sepratestring(service.title)[1]
                            : ""}
                        </span>
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 CmsPage">
                      {helpers.helpers1(service.image) ? (
                        <picture>
                          <img
                            loading="lazy"
                            src={
                              process.env.REACT_APP_API +
                              "uploads/service/" +
                              service.image
                            }
                            alt={service.title}
                            className="h-auto float-md-end mb-md-5 ms-md-5 col-md-3"
                            width="484"
                            height="524"
                          />
                        </picture>
                      ) : (
                        <picture>
                          <source
                            srcSet={
                              process.env.REACT_APP_API +
                              "uploads/service/webp/" +
                              service.image +
                              ".webp"
                            }
                            type="image/webp"
                          />
                          <source
                            srcSet={
                              process.env.REACT_APP_API +
                              "uploads/service/avif/" +
                              service.image +
                              ".avif"
                            }
                            type="image/avif"
                          />
                          <img
                            loading="lazy"
                            src={
                              process.env.REACT_APP_API +
                              "uploads/service/jpg/" +
                              service.image +
                              ".jpg"
                            }
                            alt={service.title}
                            className="h-auto float-md-end mb-md-5 ms-md-5 col-md-3"
                            width="484"
                            height="524"
                          />
                        </picture>
                      )}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: service.description,
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </section>
            {categories.length > 0 ? (
              <section className=" Services">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-9 col-md-10 text-center">
                      <span className="SubTitle">
                        Electrical & Communications
                      </span>
                      <h2 className="Heading">Other Services</h2>
                    </div>
                  </div>
                  <OwlCarousel
                    className="text-center"
                    items={5}
                    margin={30}
                    loop={false}
                    dots={false}
                    nav={false}
                    autoplay={false}
                    autoplayTimeout={2000}
                    autoplayHoverPause={true}
                    responsive={{
                      0: { items: 1 },
                      350: { items: 2 },
                      460: { items: 3 },
                      600: { items: 3 },
                      768: { items: 4 },
                      992: { items: 4 },
                      1200: { items: 5 },
                      1600: { items: 5 },
                    }}
                  >
                    {categories.map((categ, o) => (
                      <Link
                        to={"/service/" + categ.alias}
                        key={o}
                        className="card ProBlock SerBlock"
                      >
                        <div className="card-header">
                          {helpers.helpers1(categ.image) ? (
                            <picture>
                              <img
                                loading="lazy"
                                src={
                                  process.env.REACT_APP_API +
                                  "uploads/service/" +
                                  categ.image
                                }
                                alt={categ.title}
                                width="121"
                                height="131"
                              />
                            </picture>
                          ) : (
                            <picture>
                              <source
                                srcSet={
                                  process.env.REACT_APP_API +
                                  "uploads/service/webp/" +
                                  categ.image +
                                  ".webp"
                                }
                                type="image/webp"
                              />
                              <source
                                srcSet={
                                  process.env.REACT_APP_API +
                                  "uploads/service/avif/" +
                                  categ.image +
                                  ".avif"
                                }
                                type="image/avif"
                              />
                              <img
                                loading="lazy"
                                src={
                                  process.env.REACT_APP_API +
                                  "uploads/service/jpg/" +
                                  categ.image +
                                  ".jpg"
                                }
                                alt={categ.title}
                                width="121"
                                height="131"
                              />
                            </picture>
                          )}
                        </div>
                        <div className="card-body text-center px-2">
                          <h3 className="h5">{categ.title}</h3>
                        </div>
                      </Link>
                    ))}
                  </OwlCarousel>
                  <div className="PreNext d-lg-none">
                    <span className="btn prev"></span>
                    <span className="btn next"></span>
                  </div>
                </div>
              </section>
            ) : (
              ""
            )}
          </main>
        </>
      )}
    </>
  );
}
