import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import '../css/testimonial.css';
import Meta from './Meta';
import TestiLoader from '../loader/Testi';
import helpers from '../Helper';
export default function Testimonial() {
    const [testimonials, settestimonial] = useState([]);
    const [loader, setLoader] = useState(false);
    async function gettestimonials() {
        await axios.get(process.env.REACT_APP_API+'api/testimonials').then((responose) => {
            settestimonial(responose.data.data.data);
            setLoader(false);
        });
    }
    useEffect(() => {
        setLoader(true);
        gettestimonials();
    }, []);

    
    return (
        <>
            <Meta id={5} />
            <main>
                <section className="InnerHead bg-black">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 150"><path fill="var(--thm)" d="M1600,82.42s-107.75-175-500-15c-436.7,178.11-853.54,10-962,10S17.91,99.69,0,109.42c.06,0,0,37,0,37H1600Z" /><path fill="var(--white)" d="M1600,118.42s-94.07-190.17-470-54c-458.06,165.92-926,27-1042,27-42.61,0-70.09,7.27-88,17,.06,0,0,42,0,42H1600Z" /></svg>
                    <picture>
                        <source srcSet={require("../assets/img/banner1.webp")} type="image/webp" />
                        <img src={require("../assets/img/banner1.jpg")} className="imgbg" alt="Testimonial" width="1400" height="520" />
                    </picture>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9 text-center">
                                <h1 className="fw-normal text-white">Testimonial</h1>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/"><img src={require("../assets/img/home.svg").default} width="14" height="14" alt="Home" /></Link></li>
                                    <li className="breadcrumb-item"><span>Testimonial</span></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        {
                            loader ?
                                <TestiLoader />
                                :
                                <div className="grid-wrapper testi text-center">
                                    {
                                        testimonials.map((testimonial, i) =>

                                            <div className="testibox" key={i}>
                                                <div className="testitext"><div dangerouslySetInnerHTML={{ __html: testimonial.description }}></div></div>
                                                {
                                                    helpers.helpers1(testimonial.image) ?
                                                        <picture>
                                                            <img loading="lazy" src={process.env.REACT_APP_API+'uploads/testimonial/' + testimonial.image} alt="improve" width="121" height="131" />
                                                        </picture>
                                                        :
                                                        <picture>
                                                            <source srcSet={process.env.REACT_APP_API+'uploads/testimonial/webp/' + testimonial.image + '.webp'} type="image/webp" />
                                                            <source srcSet={process.env.REACT_APP_API+'uploads/testimonial/avif/' + testimonial.image + '.avif'} type="image/avif" />
                                                            <img loading="lazy" src={process.env.REACT_APP_API+'uploads/testimonial/jpg/' + testimonial.image + '.jpg'} alt="improve" width="121" height="131" />
                                                        </picture>
                                                }
                                                <span className="d-block h6">{testimonial.title}</span>
                                            </div>

                                        )
                                    }
                                </div>
                        }

                    </div>
                </section>
            </main>
        </>
    )
}
