import React, { useEffect, useState } from 'react'
import '../css/career.css';
import Loader from './PageLoader';
import Meta from './Meta';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import PageLoader from '../loader/Career';
export default function Career() {
    const [cmsdata, setcmsdata] = useState([]);
    const [careerlists, setcareerlists] = useState([]);
    const [career, setCareer] = useState([]);
    const [loader, setLoader] = useState(false);
    const [pageloader, setLoadingrtPro] = useState(false);
    const [btnloader, setBtnloader] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [experience, setExperience] = useState("");
    const [resume, setResume] = useState("");
    const [job, setJob] = useState("");
    const [error, setError] = useState({ name: "", email: "", phone: "", experience: "", resume: "" });

    async function getcareerdata() {
        await axios.get(process.env.REACT_APP_API + 'api/career').then((response) => {
            setcareerlists(response.data.data.data);
            setLoadingrtPro(false);
        });
    }

    async function getdata() {
        await axios.get(process.env.REACT_APP_API + 'api/getcms/10').then((response) => {
            setcmsdata(response.data.data);
        });
    }

    useEffect(() => {
        setLoadingrtPro(true);
        getcareerdata();
    }, []);

    useEffect(() => {
        getdata();
    }, []);

    async function JobSubmit(e) {
        setBtnloader(true);
        e.preventDefault();
        let callback = { name: name, email: email, phone: phone, experience: experience, resume: resume, job_id: job }
        let config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        }
        await axios.post(process.env.REACT_APP_API + 'api/applyjob/', callback, config)
            .then((responose) => {
                toast.success(responose.data.message);
                setBtnloader(false);
                setName("");
                setEmail("");
                setPhone("");
                setExperience("");
                setResume("");
                $('#CareerForm').modal('hide');
                $('.Carrefrm').trigger('reset');
                setError({ name: "", email: "", phone: "", experience: "", resume: "" });

            })
            .catch((errors) => {
                if (errors.response !== undefined) {
                    let rterror = errors.response.data.errors;
                    setError({
                        name: rterror.name,
                        email: rterror.email,
                        phone: rterror.phone,
                        experience: rterror.experience,
                        resume: rterror.resume
                    });
                    setBtnloader(false);
                }

            })
    }
    async function getcareerdtldata(id) {
        setLoader(true);
        await axios.get(process.env.REACT_APP_API + 'api/career/' + id).then((response) => {
            setCareer(response.data.data);
            setLoader(false);
        });
    }
    return (
        <>
            <Meta id={11} />
            <main>
                <section className="InnerHead bg-black">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 150"><path fill="var(--thm)" d="M1600,82.42s-107.75-175-500-15c-436.7,178.11-853.54,10-962,10S17.91,99.69,0,109.42c.06,0,0,37,0,37H1600Z" /><path fill="var(--white)" d="M1600,118.42s-94.07-190.17-470-54c-458.06,165.92-926,27-1042,27-42.61,0-70.09,7.27-88,17,.06,0,0,42,0,42H1600Z" /></svg>
                    <picture>
                        <source srcSet={require("../assets/img/banner1.webp")} type="image/webp" />
                        <img src={require("../assets/img/banner1.jpg")} className="imgbg" alt="FAQs" width="1400" height="520" />
                    </picture>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9 text-center">
                                <h1 className="fw-normal text-white">Careers</h1>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/"><img src={require("../assets/img/home.svg").default} width="14" height="14" alt="Home" /></Link></li>
                                    <li className="breadcrumb-item"><span>Careers</span></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    {
                        pageloader ?
                            <PageLoader />
                            :
                            <div className="container">
                                <div className="row mb-4 justify-content-center">
                                    <div className="col-lg-9 col-md-10 text-center">
                                        {
                                            cmsdata.title ?
                                                <span className="SubTitle">{cmsdata.title}</span>
                                                :
                                                ''
                                        }
                                        <h2 className="Heading">{cmsdata.heading}</h2>
                                        <span dangerouslySetInnerHTML={{ __html: cmsdata.description }} />
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-9 col-md-11 mb-4">
                                        {
                                            careerlists.map((lists, a) =>
                                                <div key={a} className="card card-body Openings mb-4 px-md-4 py-md-4 justify-content-between align-items-center flex-md-row">
                                                    <div>
                                                        <h3 className="h5 m-0 fw-bold">{lists.title}</h3>
                                                        <p className="thm1 m-0">{lists.department}</p>
                                                    </div>
                                                    <span>
                                                        <a data-bs-toggle="modal" onClick={() => getcareerdtldata(lists.id)} href="#Detail" className="btn btn-thm3 mt-md-0 me-3">Description</a>
                                                        <a data-bs-toggle="modal" onClick={() => setJob(lists.id)} href="#CareerForm" className="btn btn-thm mt-md-0">Apply Now</a>
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                    }

                </section>
            </main>
            <div className="modal fade PopupM" id="CareerForm" tabIndex="-1" aria-labelledby="CareerFormLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <form onSubmit={JobSubmit} className="modal-content Carrefrm">
                        <div className="modal-header border-0 d-none">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body pt-5 px-md-5">
                            <h3 className="fw-bold h4 thm">Apply Now</h3>
                            <p>Fill out the form below and apply to be a part of the Intelligence team.</p>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
                                        <label htmlFor="name" className="form-label">Name<span className="text-danger">*</span></label>
                                        {error.name ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.name}</span> : ''}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="number" className="form-control" id="experience" value={experience} onChange={(e) => setExperience(e.target.value)} placeholder="Experience (in years)" />
                                        <label htmlFor="experience" className="form-label">Experience (in years)<span className="text-danger">*</span></label>
                                        {error.experience ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.experience}</span> : ''}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" />
                                        <label htmlFor="email" className="form-label">Email Address<span className="text-danger">*</span></label>
                                        {error.email ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.email}</span> : ''}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="number" className="form-control" id="mobile" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Contact Number" />
                                        <label htmlFor="mobile" className="form-label">Contact Number<span className="text-danger">*</span></label>
                                        {error.phone ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.phone}</span> : ''}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="formFileLg" className="form-label">Upload your resume<span className="text-danger">*</span></label>
                                    <div className="form-floating mb-3">
                                        <input className="form-control" id="formFileLg" onChange={(e) => setResume(e.target.files[0])} type="file" />
                                    </div>
                                    {error.resume ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{error.resume}</span> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer pt-4 border-top pb-5 d-block text-center">
                            <p>Please take a minute to review if the above details are correct.</p>
                            <button type="button" className="btn btn-thm3" data-bs-dismiss="modal">Close</button>
                            {
                                btnloader ?
                                    <button type="button" className="btn btn-thm disabled"><FontAwesomeIcon icon={faSpinner} className="fa-spin me-1" /> Loading...</button>
                                    :
                                    <button className="btn btn-thm">Submit Application</button>
                            }
                        </div>
                    </form>
                </div>
            </div>
            <div className="modal fade" id="Detail" tabIndex="-1" aria-labelledby="CareerFormLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <form action="#" className="modal-content">
                        <div className="modal-header border-0 d-none">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body CmsPage px-md-4">
                            {
                                !loader ?
                                    <>
                                        <h2>{career.title ?? ''}</h2>
                                        <p>{career.department ?? ''}</p>
                                        <div dangerouslySetInnerHTML={{ __html: career.description ?? '' }} />
                                    </>
                                    :
                                    <Loader />
                            }

                        </div>
                        <div className="modal-footer border-top d-block text-center">
                            <button type="button" className="btn btn-thm3 btn-sm mt-0" data-bs-dismiss="modal">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
