import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function Footer() {
    const [categories,setCategories] = useState([]);
    const [btnloader,setBtnloader] = useState(false);
    const [newsletter,setNewsletter] = useState('');
    useEffect(()=>{
        async function getCategorie(){
            await axios.get(process.env.REACT_APP_API+'api/service-category/').then((response)=>{
                setCategories(response.data.data.data);
            });
        }
        getCategorie();
    },[setCategories]);
    
    async function SubmitNewsletter(e){
        e.preventDefault();
        setBtnloader(true);
        let callback = { email:newsletter }
        await axios.post(process.env.REACT_APP_API+'api/newsletter/',callback)
        .then((responose)=>{
            toast.success(responose.data.message);
            setBtnloader(false); 
            setNewsletter("");                     
        })
        .catch((errors)=>{
            let error = errors.response.data;
            toast.error(error.message);
            setBtnloader(false);
        })
    }
    return (
        <>
            <footer>
                <div className="container">
                    <div className="FooterTop grey">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <p className="m-0 thm1">Subscribe to Newsletter</p>
                                <h2 className="h4 m-0"><strong className="thm">Electrical Services has </strong> never been so easy.</h2>
                            </div>
                            <div className="col-lg-5">
                                <form onSubmit={SubmitNewsletter} className="subscribeform">                                    
                                    <div className="input-group mb-0">
                                        <input type="text" className="form-control" name="subscribe_email" value={newsletter} onChange={(e)=>setNewsletter(e.target.value)} placeholder="Enter your email" aria-label="Enter your email" aria-describedby="searchbox" />
                                        {
                                            btnloader?
                                                <button className="input-group-text" id="searchbox"><FontAwesomeIcon icon={faSpinner} className="fa-spin me-1"/></button>
                                            :
                                            <button className="input-group-text" id="searchbox">Subscribe</button>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2 text-center">
                            <Link to="/" className="d-inline-block"><img src={require('../../assets/img/logo.svg').default} width="250" height="57" className="flogo" alt="Electrical & Communications" /></Link>
                        </div>
                        <div className="col-lg-2">
                            <h3 className="h6 thm mb-3">General</h3>
                            <ul className="links">
                                <li><Link to="/about">About Us</Link></li>
                                <li><Link to="/careers">Careers</Link></li>
                                <li><Link to="/blogs">Blog</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-6">
                            <h3 className="h6 thm mb-3">Services</h3>
                            <ul className="links ser">
                                {
                                    categories.map((category,c)=>
                                    <li key={c}>
                                        <Link title={category.title} to={"/services/"+category.alias}>
                                            {category.title}
                                        </Link>
                                    </li>                                        
                                    )
                                }
                            </ul>
                        </div>
                        <div className="col-lg-2">
                            <h3 className="h6 thm mb-3">Informations</h3>
                            <ul className="links">
                                <li><Link to="/faq">FAQ</Link></li>
                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                <li><Link to="/terms-and-conditions">Terms of Conditions</Link></li>
                            </ul>
                        </div>

                    </div>
                </div>
                <div className="fbottom mt-4">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-7 text-start">
                                <p><small>&#169; <script>document.write(new Date().getFullYear());</script> <strong>Electrical & Communications</strong>. All Rights Reserved.</small></p>
                            </div>
                            <div className="col-md-5 text-end">
                                <p><small>Made with &nbsp;<strong><span className="text-danger">&#10084;</span></strong>&nbsp; by <strong><a href="https://www.digitalfolks.co/" rel="noreferrer" target="_blank">Digital Folks</a></strong></small></p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* <a href="#" className="BackTop" style="display:none"><span><i></i><span>Back to top</span></span></a> */}
            <ToastContainer/>
        </>
    )
}
