import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
  <div className="container">
    <ContentLoader viewBox="0 0 600 100" backgroundColor="#f3f3f3" foregroundColor="#c7f9ff">
      <rect x="95" y="40" rx="2" ry="2" width="390" height="21" /> 
      <rect x="127" y="80" rx="2" ry="2" width="145" height="11" /> 
      <rect x="307" y="80" rx="2" ry="2" width="145" height="11" /> 
    </ContentLoader>
    <ContentLoader viewBox="0 0 900 350" backgroundColor="#f3f3f3" foregroundColor="#c7f9ff">
    <rect x="30" y="60" rx="0" ry="0" width="250" height="120" />
    <rect x="30" y="189" rx="0" ry="0" width="250" height="15" />
    <rect x="30" y="221" rx="0" ry="0" width="140" height="15" />
    <rect x="315" y="60" rx="0" ry="0" width="250" height="120" />
    <rect x="315" y="189" rx="0" ry="0" width="250" height="15" />
    <rect x="315" y="221" rx="0" ry="0" width="140" height="15" />
    <rect x="600" y="60" rx="0" ry="0" width="250" height="120" />
    <rect x="600" y="189" rx="0" ry="0" width="250" height="15" />
    <rect x="600" y="221" rx="0" ry="0" width="140" height="15" />
  </ContentLoader>
  </div>
)

export default MyLoader