import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'

export default function Meta(props) {
    const [meta,setMeta] = useState([]);
     const fetchMeta = async () => {
        let id = props.id;
        await axios.get(process.env.REACT_APP_API+'api/meta/'+id).then((responose)=>{
            setMeta(responose.data.data);
        });
    }
    useEffect(()=>{        
        fetchMeta();
        // eslint-disable-next-line
    },[]);
  return (
    <>
        <Helmet>
            <title>{meta ? meta.meta_title : 'Welcome to Electrical & Communications'}</title>
            <meta name="description" content={meta ? meta.meta_description : 'Welcome to Electrical & Communications'} />
            <meta name="keywords" content={meta ? meta.meta_keywords : 'Welcome to Electrical & Communications'} />
        </Helmet>
    </>
  )
}
