import axios from 'axios';
import React, { useEffect, useState } from 'react'
import '../css/contact.css';
import Meta from './Meta';

import { Link } from 'react-router-dom';
import Getintouch from './layouts/Getintouch';
import helpers from '../Helper';

export default function Contact() {
  const [lists, setlists] = useState([]);
  const [contactdata, setcontactdata] = useState([]);
  async function getcontactdata() {
    await axios.get(process.env.REACT_APP_API+'api/getcms/4').then((responose) => {
      setcontactdata(responose.data.data);
    });
  }
  async function getcontactlist() {
    await axios.get(process.env.REACT_APP_API+'api/contacts').then((responose) => {
      setlists(responose.data.data.data);
    });
  }  
  useEffect(() => {
    getcontactdata();
    getcontactlist();
  }, []);
  
  return (
    <>
      <Meta id={3} />
      <main>
        <section className="InnerHead bg-black">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 150"><path fill="var(--thm)" d="M1600,82.42s-107.75-175-500-15c-436.7,178.11-853.54,10-962,10S17.91,99.69,0,109.42c.06,0,0,37,0,37H1600Z" /><path fill="var(--white)" d="M1600,118.42s-94.07-190.17-470-54c-458.06,165.92-926,27-1042,27-42.61,0-70.09,7.27-88,17,.06,0,0,42,0,42H1600Z" /></svg>
            <picture>
                <source srcSet={require("../assets/img/banner1.webp")} type="image/webp" />
                <img src={require("../assets/img/banner1.jpg")} className="imgbg" alt="FAQs" width="1400" height="520" />
            </picture>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 text-center">
                <h1 className="fw-normal text-white">Contact Us</h1>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item"><Link to="/"><img src={require("../assets/img/home.svg").default} width="14" height="14" alt="Home" /></Link></li>
                  <li className="breadcrumb-item"><span>Contact Us</span></li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="Sec6 Contact">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9 col-md-10 text-center">
                {
                  contactdata.title ?
                    <span className="SubTitle">{contactdata.title}</span>
                    :
                    ''
                }
                <h2 className="Heading">
                  {contactdata.heading ? helpers.sepratestring(contactdata.heading)[0] : ''} <span>{contactdata.heading ? helpers.sepratestring(contactdata.heading)[1] : ''}</span>
                </h2>
                <div dangerouslySetInnerHTML={{ __html: contactdata.description }} />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                {
                  helpers.helpers1(contactdata.image) ?
                    <picture>
                      <img loading="lazy" src={process.env.REACT_APP_API+'uploads/cms/' + contactdata.image} alt="24/7 Emergency Service" className="w-100" width="750" height="400" />
                    </picture>
                    :
                    <picture>
                      <source srcSet={process.env.REACT_APP_API+'uploads/cms/avif/' + contactdata.image + '.avif'} type="image/avif" />
                      <source srcSet={process.env.REACT_APP_API+'uploads/cms/webp/' + contactdata.image + '.webp'} type="image/webp" />
                      <img loading="lazy" src={process.env.REACT_APP_API+'uploads/cms/jpg/' + contactdata.image + '.jpg'} alt="24/7 Emergency Service" className="w-100" width="750" height="400" />
                    </picture>
                }

                {
                  lists.map((list, i) =>
                    <div className="bgthm ConInfo mb-3" key={i}>
                      <ul>
                        <li><img src={require("../assets/img/location-w.svg").default} alt="location"/> <span><strong>{list.title}</strong><br />{list.address}</span></li>
                        {
                          list.phone ?
                            <li><img src={require("../assets/img/call-w.svg").default} alt="call" /> <a href={'tel:' + list.phone}>{list.phone}</a></li>
                            :
                            ''
                        }
                        {
                          list.email ?
                            <li><img src={require("../assets/img/mail-w.svg").default} alt="mail" /> <a href={'mailto:' + list.email}>{list.email}</a></li>
                            :
                            ''
                        }
                      </ul>
                    </div>
                  )
                }

              </div>
              <div className="col-lg-8">
                <Getintouch/>
              </div>
            </div>
          </div>
        </section>
      </main>
      
    </>
  )
}
