import axios from 'axios';
import React, { useEffect, useState } from 'react';
import '../css/blog.css';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Meta from './Meta';
import BlogLoder from '../loader/Blog';
import helpers from '../Helper';
export default function Blog() {
  const [blogdata, setblogdata] = useState([]);
  const [cmsdata, setcmsdata] = useState([]);
  const [loader, setLoader] = useState(false);

  const getblogdata = async () => {
    await axios.get(process.env.REACT_APP_API + 'api/blogs').then((responose) => {
      setblogdata(responose.data.data.data);
      setLoader(false);
    });
  }
  const getcmsdata = async () => {
    await axios.get(process.env.REACT_APP_API + 'api/getcms/12').then((response) => {
      setcmsdata(response.data.data);
    });
  }

  useEffect(() => {
    setLoader(true);
    getblogdata();
    getcmsdata();
  }, []);

  return (
    <>
      <Meta id={6} />
      <main>
        <section className="InnerHead bg-black">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 150"><path fill="var(--thm)" d="M1600,82.42s-107.75-175-500-15c-436.7,178.11-853.54,10-962,10S17.91,99.69,0,109.42c.06,0,0,37,0,37H1600Z" /><path fill="var(--white)" d="M1600,118.42s-94.07-190.17-470-54c-458.06,165.92-926,27-1042,27-42.61,0-70.09,7.27-88,17,.06,0,0,42,0,42H1600Z" /></svg>
          <picture>
            <source srcSet="img/banner1.webp" type="image/webp" />
            <source srcSet="img/banner1.avif" type="image/avif" />
            <img src="img/banner1.jpg" className="imgbg" alt="Blog" width="1400" height="520" />
          </picture>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 text-center">
                <h1 className="fw-normal text-white">Blog</h1>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item"><Link to="/"><img src={require("../assets/img/home.svg").default} width="14" height="14" alt="Home" /></Link></li>
                  <li className="breadcrumb-item"><span>Blog</span></li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        {
          !loader ?
            <section className="BlogSec4">
              <div className="container">
                <div className="row mb-4 justify-content-center">
                  <div className="col-lg-9 col-md-10 text-center">
                    {
                      cmsdata.title ?
                        <span className="SubTitle">{cmsdata.title}</span>
                        :
                        ''
                    }
                    <h2 className="Heading">
                      {cmsdata.heading ? helpers.sepratestring(cmsdata.heading)[0] : ''} <span>{cmsdata.heading ? helpers.sepratestring(cmsdata.heading)[1] : ''}</span>
                    </h2>
                    <div dangerouslySetInnerHTML={{ __html: cmsdata.description }} />
                  </div>
                </div>
                <div className="row blogbox justify-content-center">
                  {
                    blogdata.map((blog, i) =>
                      <div className="col-lg-4 col-sm-6 blog mb-4" key={i}>
                        <Link to={`/blog/${blog.alias}`} className="card">
                          <div className="card-header">
                            {
                              helpers.helpers1(blog.image) ?
                                <picture>
                                  <img loading="lazy" src={process.env.REACT_APP_API + 'uploads/blog/' + blog.image} alt={blog.title} className="h-auto float-md-end mb-md-5 ms-md-5 col-md-3" width="200" height="131" />
                                </picture>
                                :
                                <picture>
                                  {/* <source srcSet={process.env.REACT_APP_API + 'uploads/blog/avif/' + blog.image + '.avif'} type="image/avif" /> */}
                                  <source srcSet={process.env.REACT_APP_API + 'uploads/blog/webp/' + blog.image + '.webp'} type="image/webp" />
                                  <img loading="lazy" src={process.env.REACT_APP_API + 'uploads/blog/jpg/' + blog.image + '.jpg'} alt={blog.title} className="h-auto float-md-end mb-md-5 ms-md-5 col-md-3" width="200" height="131" />
                                </picture>
                            }

                          </div>
                          <div className="card-body px-0 text-center">
                            <span className="badge text-black bgthm1">{moment(blog.post_date).format('DD MMMM YYYY')}</span>
                            <h3 className="h5 mt-2">{blog.title}</h3>
                          </div>
                        </Link>
                      </div>
                    )
                  }
                </div>

              </div>
            </section>
            :
            <BlogLoder />
        }
      </main>
    </>
  )
}
