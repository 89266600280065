const helpers = {
    helpers1 : (image)=>{
        if (image) {
            let imageArr = image.split('.');
            imageArr = imageArr.slice(-1);
            let stringimg = imageArr.toString(); 
            if (stringimg === "webp" || stringimg === "svg" || stringimg === "avif") {          
                return true;
            } else {
            return false;
            }
        } else {
            return false;
        }
    },
    sepratestring : (string) =>{
        if(string){
            const text = string.split(' ');
            const half = Math.ceil(text.length / 2);   
            const firstHalf = text.slice(0,half);
            const secondHalf = text.slice(half);
            const stringfirst = firstHalf.join(' ');
            const stringsecond = secondHalf.join(' ');
            return [stringfirst,stringsecond];
        }else{
            return ['',''];
        }
        
    }
}
export default helpers;