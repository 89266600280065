import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
  <div className="container">
  <ContentLoader viewBox="0 0 600 100" backgroundColor="#f3f3f3" foregroundColor="#c7f9ff">
    <rect x="95" y="10" rx="2" ry="2" width="390" height="21" /> 
    <rect x="127" y="50" rx="2" ry="2" width="145" height="11" /> 
    <rect x="307" y="50" rx="2" ry="2" width="145" height="11" /> 
  </ContentLoader>
    <ContentLoader height={475} width={1260} backgroundColor="#f3f3f3" foregroundColor="#c7f9ff">
      <circle cx="25" cy="112" r="15" />
      <circle cx="25" cy="160" r="15" />
      <circle cx="25" cy="210" r="15" />
      <rect x="10" y="18" rx="0" ry="0" width="900" height="9" />
      <rect x="950" y="10" rx="0" ry="0" width="844" height="244" />
      <rect x="10" y="41" rx="0" ry="0" width="900" height="9" />
      <rect x="10" y="65" rx="0" ry="0" width="800" height="9" />
      <rect x="50" y="100" rx="0" ry="0" width="715" height="9" />
      <rect x="50" y="120" rx="0" ry="0" width="715" height="10" />
      <rect x="50" y="150" rx="0" ry="0" width="715" height="9" />
      <rect x="50" y="170" rx="0" ry="0" width="715" height="10" />
      <rect x="50" y="200" rx="0" ry="0" width="715" height="9" />
      <rect x="50" y="220" rx="0" ry="0" width="715" height="10" />
      <rect x="10" y="250" rx="0" ry="0" width="900" height="9" />
      <rect x="10" y="270" rx="0" ry="0" width="1500" height="9" />
      <rect x="10" y="290" rx="0" ry="0" width="1500" height="9" />
      <rect x="10" y="310" rx="0" ry="0" width="1500" height="9" />
      <rect x="10" y="330" rx="0" ry="0" width="1500" height="9" />
      <rect x="10" y="350" rx="0" ry="0" width="1500" height="9" />
      <rect x="10" y="370" rx="0" ry="0" width="1500" height="9" />
    </ContentLoader>
  </div>
)

export default MyLoader