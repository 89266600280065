import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
  <div className="container">
    <ContentLoader viewBox="0 0 400 190" backgroundColor="#f3f3f3" foregroundColor="#c7f9ff">
      <rect x="0" y="13" rx="4" ry="4" width="400" height="9" />
      <rect x="0" y="29" rx="4" ry="4" width="100" height="9" />
      <rect x="0" y="50" rx="4" ry="4" width="400" height="9" />
      <rect x="0" y="65" rx="4" ry="4" width="400" height="9" />
      <rect x="0" y="79" rx="4" ry="4" width="100" height="9" />
      <rect x="0" y="105" rx="4" ry="4" width="400" height="9" />
      <rect x="0" y="120" rx="4" ry="4" width="100" height="9" />
      <rect x="0" y="145" rx="4" ry="4" width="400" height="9" />
      <rect x="0" y="160" rx="4" ry="4" width="400" height="9" />
      <rect x="0" y="175" rx="4" ry="4" width="100" height="9" />
    </ContentLoader>
  </div>
)

export default MyLoader