import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import helpers from "../Helper";
import Datanotfound from "./Datanotfound";
import Page404 from "./layouts/Page404";
export default function BlogCategory() {
  const params = useParams();
  const [catgeory, setCategory] = useState([]);
  const [blogdata, setblogdata] = useState([]);
  const [cmsdata, setcmsdata] = useState([]);
  const [dataNotFound, setDataNotFound] = useState(false);
  const getblogdata = async () => {
    await axios
      .get(
        process.env.REACT_APP_API + "api/blogs?categoryalias=" + params.alias
      )
      .then((responose) => {
        setCategory(responose?.data?.category);
        
        setblogdata(responose.data.data.data);
      })
      .catch((err) => {
        setDataNotFound(!dataNotFound);
      });
  };
  const getcmsdata = async () => {
    await axios
      .get(process.env.REACT_APP_API + "api/getcms/12")
      .then((response) => {
        setcmsdata(response.data.data);
      });
  };
  useEffect(() => {
    getblogdata();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getcmsdata();
  }, []);

  return (
    <>
      {dataNotFound ? (
        <Page404 />
      ) : (
        <>
          <Helmet>
            <title>{catgeory?.meta_title}</title>
            <meta
              name="description"
              content={catgeory?.meta_description}
            />
            <meta
              name="keywords"
              content={catgeory?.meta_keywords}
            />
          </Helmet>
          <main>
            <section className="InnerHead bg-black">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 150">
                <path
                  fill="var(--thm)"
                  d="M1600,82.42s-107.75-175-500-15c-436.7,178.11-853.54,10-962,10S17.91,99.69,0,109.42c.06,0,0,37,0,37H1600Z"
                />
                <path
                  fill="var(--white)"
                  d="M1600,118.42s-94.07-190.17-470-54c-458.06,165.92-926,27-1042,27-42.61,0-70.09,7.27-88,17,.06,0,0,42,0,42H1600Z"
                />
              </svg>
              <picture>
                <source
                  srcSet={require("../assets/img/banner1.webp")}
                  type="image/webp"
                />
                <img
                  src={require("../assets/img/banner1.jpg")}
                  className="imgbg"
                  alt="Blog Details"
                  width="1400"
                  height="520"
                />
              </picture>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-9 text-center">
                    <h1 className="fw-normal text-white">{catgeory?.title}</h1>
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <img
                            src={require("../assets/img/home.svg").default}
                            width="14"
                            height="14"
                            alt="Home"
                          />
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/blogs">Blog</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <span>{catgeory?.title}</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
            <section className="BlogSec4">
              <div className="container">
                <div className="row mb-4 justify-content-center">
                </div>
                <div className="row blogbox">
                  {blogdata.length > 0 ? (
                    blogdata.map((blog, i) => (
                      <div className="col-lg-4 col-sm-6 blog mb-4" key={i}>
                        <Link to={`/blog/${blog.alias}`} className="card">
                          <div className="card-header">
                            {helpers.helpers1(blog.image) ? (
                              <picture>
                                <img
                                  loading="lazy"
                                  src={
                                    process.env.REACT_APP_API +
                                    "uploads/blog/" +
                                    blog.image
                                  }
                                  alt={blog.title}
                                  className="h-auto float-md-end mb-md-5 ms-md-5 col-md-3"
                                  width="200"
                                  height="131"
                                />
                              </picture>
                            ) : (
                              <picture>
                                {/* <source srcSet={process.env.REACT_APP_API+'uploads/blog/avif/' + blog.image + '.avif'} type="image/avif" /> */}
                                <source
                                  srcSet={
                                    process.env.REACT_APP_API +
                                    "uploads/blog/webp/" +
                                    blog.image +
                                    ".webp"
                                  }
                                  type="image/webp"
                                />
                                <img
                                  loading="lazy"
                                  src={
                                    process.env.REACT_APP_API +
                                    "uploads/blog/jpg/" +
                                    blog.image +
                                    ".jpg"
                                  }
                                  alt={blog.title}
                                  className="h-auto float-md-end mb-md-5 ms-md-5 col-md-3"
                                  width="200"
                                  height="131"
                                />
                              </picture>
                            )}
                          </div>
                          <div className="card-body px-0 text-center">
                            <span className="badge text-black bgthm1">
                              {moment(blog.post_date).format("DD MMMM YYYY")}
                            </span>
                            <h3 className="h5 mt-2">{blog.title}</h3>
                          </div>
                        </Link>
                      </div>
                    ))
                  ) : (
                    <Datanotfound />
                  )}
                </div>
                <div
                      dangerouslySetInnerHTML={{ __html: catgeory?.description }}
                    />
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
}
