import axios from 'axios';
import React, { useState } from 'react'
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast} from 'react-toastify';
export default function Getintouch() {
    const [quotename, setQuoteName] = useState("");
    const [quoteemail, setQuoteEmail] = useState("");
    const [quotephone, setQuotePhone] = useState("");
    const [quoteresponse, setQuoteResponse] = useState("");
    const [quoteproblem, setQuoteproblem] = useState("");
    const [quoteerror, setQuoteError] = useState({ name: "", email: "", phone: "", response: "", problem: "" });
    const [btnloader, setBtnloader] = useState(false);
    async function QuoteSubmit(e) {
        setBtnloader(true);
        e.preventDefault();
        let callback = { name: quotename, email: quoteemail, phone: quotephone, problem: quoteproblem, response: quoteresponse }
        console.log(callback);
        await axios.post(process.env.REACT_APP_API + 'api/quoterequest/', callback)
            .then((responose) => {
                toast.success(responose.data.message);
                setBtnloader(false);
                setQuoteError({ name: "", email: "", phone: "", response: "", problem: "" });
                setQuoteName("");
                setQuoteEmail("");
                setQuotePhone("");
                setQuoteResponse("");
                setQuoteproblem("");
            })
            .catch((errors) => {
                let error = errors?.response?.data?.errors;
                setQuoteError({
                    name: error.name,
                    email: error.email,
                    phone: error.phone,
                    response: error.response,
                    problem: error.problem
                });
                setBtnloader(false);
            })
    }
    return (
        <>
            <form id="formsubmit" onSubmit={QuoteSubmit} className="grey">
                <h3 className="h4 m-0">Get in Touch</h3><hr />
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="Name" className="form-label m-0">Your Name<span className="text-danger">*</span></label>
                        <input className="form-control" id="Name" name="Name" type="text" value={quotename} placeholder="Name" onChange={(e) => setQuoteName(e.target.value)} />
                        {quoteerror.name ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{quoteerror.name}</span> : ''}
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="Email" className="form-label m-0">Your Email ID<span className="text-danger">*</span></label>
                        <input className="form-control" id="Email" name="Email" type="email" value={quoteemail} placeholder="Email Address" onChange={(e) => setQuoteEmail(e.target.value)} />
                        {quoteerror.email ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{quoteerror.email}</span> : ''}
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="Contact" className="form-label m-0">Your Contact No.<span className="text-danger">*</span></label>
                        <input className="form-control" id="Contact" name="Phone" type="number" value={quotephone} placeholder="Contact Number" onChange={(e) => setQuotePhone(e.target.value)} maxLength="10" />
                        {quoteerror.phone ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{quoteerror.phone}</span> : ''}
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="Name" className="form-label m-0">How would you want to get a response?<span className="text-danger">*</span></label>
                        <select className="form-control form-select" value={quoteresponse} onChange={(e) => setQuoteResponse(e.target.value)}>
                            <option value="">Response Type</option>
                            <option value="email">Email ID</option>
                            <option value="phone-call">Phone Call</option>
                            <option value="text-message">Text Message</option>
                        </select>
                        {quoteerror.response ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{quoteerror.response}</span> : ''}
                    </div>
                    <div className="col-12 mb-3">
                        <label htmlFor="Message" className="form-label m-0">Your Problem / Give details about the task you require</label>
                        <textarea className="form-control" id="Message" name="Message" onChange={(e) => setQuoteproblem(e.target.value)} value={quoteproblem}></textarea>
                        {quoteerror.problem ? <span style={{ color: "#e66a6a", fontSize: "14px" }} className='error'>{quoteerror.problem}</span> : ''}
                    </div>
                </div>
                {
                    btnloader ?
                        <button type="button" className="btn btn-thm disabled"><FontAwesomeIcon icon={faSpinner} className="fa-spin me-1" /> Loading...</button>
                        :
                        <button type="submit" name="submit" className="btn btn-thm">Submit</button>
                }
            </form>
        </>
    )
}
