import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import '../css/about.css';
import Meta from './Meta';
import CmsLoader from '../loader/Cms1';
import Speciality from './Speciality';
import helpers from '../Helper';
export default function About() {
    const [aboutdata, setaboutdata] = useState([]);
    const [loader, setLoader] = useState(false);
    const getaboutdata = async () => {
        await axios.get(process.env.REACT_APP_API + 'api/getcms/6').then((response) => {
            setaboutdata(response.data.data);
            setLoader(false);
        });
    }

    useEffect(() => {
        setLoader(true);
        getaboutdata();
    }, []);


    return (
        <>
            <Meta id={2} />
            <main>
                <section className="InnerHead bg-black">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 150"><path fill="var(--thm)" d="M1600,82.42s-107.75-175-500-15c-436.7,178.11-853.54,10-962,10S17.91,99.69,0,109.42c.06,0,0,37,0,37H1600Z" /><path fill="var(--white)" d="M1600,118.42s-94.07-190.17-470-54c-458.06,165.92-926,27-1042,27-42.61,0-70.09,7.27-88,17,.06,0,0,42,0,42H1600Z" /></svg>
                    <picture>
                        <source srcSet={require("../assets/img/banner1.webp")} type="image/webp" />
                        <img src={require("../assets/img/banner1.jpg")} className="imgbg" alt="About Us" width="1400" height="520" />
                    </picture>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9 text-center">
                                <h1 className="fw-normal text-white">About Us</h1>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/"><img src={require("../assets/img/home.svg").default} width="14" height="14" alt="Home" /></Link></li>
                                    <li className="breadcrumb-item"><span>About Us</span></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="AboutSec1 Home">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600.25 649.4"><path d="M1183.91,16.92C864.28,127.69,798,447.9,729.25,511.4c-110.9,102.5-294.9,68.5-350,41s-227-81.5-312-62c-75,18.5-67,66-67,66v93h1600L1600,54.9S1339.24-36.9,1183.91,16.92Z" /></svg>
                    <div className="container">
                        <div className="row justify-content-center">
                            {
                                loader ?
                                    <CmsLoader />
                                    :
                                    <>
                                        <div className="col-lg-9 col-md-10 text-center mb-4">
                                            {aboutdata.title ? <span className="SubTitle">{aboutdata.title}</span> : ''}

                                            <h2 className="Heading">{aboutdata.heading ? helpers.sepratestring(aboutdata.heading)[0] : ''} <span>{aboutdata.heading ? helpers.sepratestring(aboutdata.heading)[1] : ''}</span></h2>
                                        </div>
                                        <div className="col-12 CmsPage">
                                            {
                                                helpers.helpers1(aboutdata.image) ?
                                                    <picture>
                                                        <img loading="lazy" src={process.env.REACT_APP_API + 'uploads/cms/' + aboutdata.image} alt={aboutdata.title} className="h-auto float-md-end mb-md-5 ms-md-5 col-md-3" width="200" height="131" />
                                                    </picture>
                                                    :
                                                    <picture>
                                                        <source srcSet={process.env.REACT_APP_API + 'uploads/cms/webp/' + aboutdata.image + '.webp'} type="image/webp" />
                                                        <source srcSet={process.env.REACT_APP_API + 'uploads/cms/avif/' + aboutdata.image + '.avif'} type="image/avif" />
                                                        <img loading="lazy" src={process.env.REACT_APP_API + 'uploads/cms/jpg/' + aboutdata.image + '.jpg'} alt={aboutdata.title} className="h-auto float-md-end mb-md-5 ms-md-5 col-md-3" width="200" height="131" />
                                                    </picture>
                                            }
                                            <span dangerouslySetInnerHTML={{ __html: aboutdata.description }} />
                                        </div>
                                    </>
                            }

                        </div>
                    </div>
                </section>
                <Speciality content={18} />
            </main>
        </>
    )
}