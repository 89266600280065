import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
  <div className="container">
    <ContentLoader viewBox="0 0 600 100" backgroundColor="#f3f3f3" foregroundColor="#c7f9ff">
      <rect x="95" y="40" rx="2" ry="2" width="390" height="21" /> 
      <rect x="127" y="80" rx="2" ry="2" width="145" height="11" /> 
      <rect x="307" y="80" rx="2" ry="2" width="145" height="11" /> 
    </ContentLoader>
    <ContentLoader viewBox="0 0 1270 600" backgroundColor="#f3f3f3" foregroundColor="#c7f9ff">
      <rect x="20" y="20" rx="8" width="220" height="200" />
      <rect x="270" y="20" rx="8" width="220" height="200" />
      <rect x="520" y="20" rx="8" width="220" height="200" />
      <rect x="770" y="20" rx="8" width="220" height="200" />
      <rect x="1020" y="20" rx="8" width="220" height="200" />
      <rect x="20" y="250" rx="8" width="220" height="200" />
      <rect x="270" y="250" rx="8" width="220" height="200" />
      <rect x="520" y="250" rx="8" width="220" height="200" />
      <rect x="770" y="250" rx="8" width="220" height="200" />
      <rect x="1020" y="250" rx="8" width="220" height="200" />
    </ContentLoader>
  </div>
)

export default MyLoader