import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import "../css/service.css";
import helpers from "../Helper";
import ServicesCat from "../loader/ServicesCat";
import Page404 from "./layouts/Page404";
import Meta from "./Meta";
export default function ServicesCategory() {
  const [dataNotFound, setDataNotFound] = useState(false);
  const [categories, setCategories] = useState([]);
  const [cmsdata, setcmsdata] = useState([]);
  const [loader, setLoader] = useState(false);
  async function getCategorie() {
    await axios
      .get(process.env.REACT_APP_API + "api/service-category/")
      .then((response) => {
        setCategories(response.data.data.data);
        setLoader(false);
      })
      .catch((err) => {
        setDataNotFound(!dataNotFound);
      });
  }
  async function getdata() {
    await axios
      .get(process.env.REACT_APP_API + "api/getcms/1")
      .then((response) => {
        setcmsdata(response.data.data);
      });
  }
  useEffect(() => {
    setLoader(true);

    getCategorie();

    getdata();
  }, []);
  return (
    <>
      {dataNotFound ? (
        <Page404 />
      ) : (
        <>
          
          <Meta id={7} />
          <main>
            <section className="InnerHead bg-black">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 150">
                <path
                  fill="var(--thm)"
                  d="M1600,82.42s-107.75-175-500-15c-436.7,178.11-853.54,10-962,10S17.91,99.69,0,109.42c.06,0,0,37,0,37H1600Z"
                />
                <path
                  fill="var(--white)"
                  d="M1600,118.42s-94.07-190.17-470-54c-458.06,165.92-926,27-1042,27-42.61,0-70.09,7.27-88,17,.06,0,0,42,0,42H1600Z"
                />
              </svg>
              <picture>
                <source
                  srcSet={require("../assets/img/banner1.webp")}
                  type="image/webp"
                />
                <img
                  src={require("../assets/img/banner1.jpg")}
                  className="imgbg"
                  alt="FAQs"
                  width="1400"
                  height="520"
                />
              </picture>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-9 text-center">
                    <h1 className="fw-normal text-white">Services</h1>
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <img
                            src={require("../assets/img/home.svg").default}
                            width="14"
                            height="14"
                            alt="Home"
                          />
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <span>Services</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>

            {loader ? (
              <ServicesCat />
            ) : (
              <section className=" Services">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-9 col-md-10 text-center">
                      {cmsdata.title ? (
                        <span className="SubTitle">{cmsdata.title}</span>
                      ) : (
                        ""
                      )}
                      <h2 className="Heading">
                        {cmsdata.heading
                          ? helpers.sepratestring(cmsdata.heading)[0]
                          : ""}{" "}
                        <span>
                          {cmsdata.heading
                            ? helpers.sepratestring(cmsdata.heading)[1]
                            : ""}
                        </span>
                      </h2>
                      <div
                        className="col-12"
                        dangerouslySetInnerHTML={{
                          __html: cmsdata.description,
                        }}
                      />
                    </div>
                  </div>

                  {categories.length > 0 ? (
                    <div className="row text-center list">
                      {categories.map((cate, ct) => (
                        <div key={ct} className="col-xl-3 col-md-4 col-6 mb-4">
                          <Link
                            to={"/services/" + cate.alias}
                            className="card  SerBlock"
                          >
                            <div className="card-header">
                              {helpers.helpers1(cate.image) ? (
                                <picture>
                                  <img
                                    loading="lazy"
                                    src={
                                      process.env.REACT_APP_API +
                                      "uploads/service/category/" +
                                      cate.image
                                    }
                                    alt={cate.title}
                                    width="121"
                                    height="131"
                                  />
                                </picture>
                              ) : (
                                <picture>
                                  <source
                                    srcSet={
                                      process.env.REACT_APP_API +
                                      "uploads/service/category/webp/" +
                                      cate.image +
                                      ".webp"
                                    }
                                    type="image/webp"
                                  />
                                  <source
                                    srcSet={
                                      process.env.REACT_APP_API +
                                      "uploads/service/category/avif/" +
                                      cate.image +
                                      ".avif"
                                    }
                                    type="image/avif"
                                  />
                                  <img
                                    loading="lazy"
                                    src={
                                      process.env.REACT_APP_API +
                                      "uploads/service/category/jpg/" +
                                      cate.image +
                                      ".jpg"
                                    }
                                    alt={cate.title}
                                    width="121"
                                    height="131"
                                  />
                                </picture>
                              )}
                            </div>
                            <div className="card-body px-2">
                              <h3 className="h5">{cate.title}</h3>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </section>
            )}
          </main>
        </>
      )}
    </>
  );
}
