import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
  <div className="container text-center mt-5 mb-5">
    <ContentLoader speed={2} width={600} height={300} viewBox="0 0 600 300" backgroundColor="#f3f3f3" foregroundColor="#c7f9ff">
    <rect x="215" y="239" rx="9" ry="9" width="151" height="52" /> 
    <rect x="95" y="120" rx="2" ry="2" width="390" height="21" /> 
    <rect x="127" y="171" rx="2" ry="2" width="145" height="21" /> 
    <rect x="307" y="172" rx="2" ry="2" width="145" height="21" /> 
    <rect x="70" y="19" rx="9" ry="9" width="444" height="48" />
  </ContentLoader>
  </div>
)

export default MyLoader