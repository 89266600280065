import React from 'react'

export default function Datanotfound() {
  return (
    <>
      <div className='text-center'>
        <p>Data Not Found....</p>
      </div>
    </>
  )
}
