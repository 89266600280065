import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
  <div className="container">
  <ContentLoader viewBox="0 0 1270 800" backgroundColor="#f3f3f3" foregroundColor="#c7f9ff">
    <rect x="30" y="20" rx="8" width="590" height="230" />
    <rect x="650" y="20" rx="8" width="590" height="230" />
    <rect x="30" y="280" rx="8" width="590" height="230" />
    <rect x="650" y="280" rx="8" width="590" height="230" />
    <rect x="30" y="540" rx="8" width="590" height="230" />
    <rect x="650" y="540" rx="8" width="590" height="230" />
  </ContentLoader>
  </div>
)

export default MyLoader